import List from "./containers/list";
import Details from "./containers/details";
import Create from "./containers/create";
import CreatePlusieur from "./containers/createPlusieur";

const routes = [
  {
    path: "/list",
    component: List,
    exact: true,
  },
  {
    path: "/details",
    component: Details,
    exact: true,
  },
  {
    path: "/create",
    component: Create,
    exact: true,
  },
  {
    path: "/createplusieur",
    component: CreatePlusieur,
    exact: true,
  },
];

export default routes;
