// routes components
import App from "modules/App";
import Auth from "modules/Auth";
// import Landing from 'modules/Landing';

const routes = [
  // {
  //   path: '/landing',
  //   component: Landing,
  //   exact: true,
  // },
  {
    path: "/auth",
    component: Auth,
  },
  {
    component: App,
  },
];

export default routes;
