import React, { useState, useMemo, useEffect } from "react";

import PageDescription from "components/PageDescription";
import useFetchElement from "hooks/useFetchElement";
import { Row, Col, Label, Button, Spinner, FormGroup } from "reactstrap";
import { conseillersByIdUrl } from "../../api/endpoints";
import Step1 from "./step/step1";
import Step2 from "./step/step2";
import Step3 from "./step/step3";
import Step4 from "./step/step4";
import Step5 from "./step/step5";
import Step6 from "./step/step6";
import Step7 from "./step/step7";
import Step8 from "./step/step8";
import Step9 from "./step/step9";
import Step10 from "./step/step10";
import Step11 from "./step/step11";
const Details = (props) => {
  const current = props.details;

  const [state, setState] = useState({
    $q: "",
  });
  const [tabs, setTabs] = useState("Informations générales");

  return (
    <div className="app-main">
      <PageDescription title="تعديل معلومات المستشار" />
      <Row>
        <Col>
          <Row
            style={{
              backgroundColor: "#F3F3F3",
              padding: "20px 20px",
              margin: "20px 0px",
              borderRadius: "10px",
            }}
          >
            <Col lg={2} md={6}>
              <span
                style={{
                  color: "#1B1B1B",
                  fontWeight: `${
                    tabs === "Informations générales" ? "700" : "400"
                  }`,
                  fontSize: "18px",
                  cursor: "pointer",
                  float: "right",
                  textAlign: "right",
                }}
                onClick={() => setTabs("Informations générales")}
              >
                معلومات عامة
              </span>
            </Col>
            <Col lg={2} md={6}>
              <span
                style={{
                  color: "#1B1B1B",
                  fontWeight: `${
                    tabs === "Informations électorales" ? "700" : "400"
                  }`,
                  fontSize: "18px",
                  cursor: "pointer",
                  float: "right",
                  textAlign: "right",
                }}
                onClick={() => {
                  setTabs("Informations électorales");
                  // actionsDispatch.viderPJ();
                }}
              >
                معلومات انتخابية
              </span>
            </Col>
            <Col lg={2} md={6}>
              <span
                style={{
                  color: "#1B1B1B",
                  fontWeight: `${tabs === "Niveau d’études" ? "700" : "400"}`,
                  fontSize: "18px",
                  cursor: "pointer",
                  float: "right",
                  textAlign: "right",
                }}
                onClick={() => setTabs("Niveau d’études")}
              >
                المستوى الدراسي
              </span>
            </Col>
            <Col lg={3} md={6}>
              <span
                style={{
                  color: "#1B1B1B",
                  fontWeight: `${
                    tabs ===
                    "Activité professionnelle de Conseiller parlementaire"
                      ? "700"
                      : "400"
                  }`,
                  fontSize: "18px",
                  cursor: "pointer",
                  float: "right",
                  textAlign: "right",
                }}
                onClick={() =>
                  setTabs(
                    "Activité professionnelle de Conseiller parlementaire"
                  )
                }
              >
                النشاط المهني للمستشار البرلماني
              </span>
            </Col>
            <Col lg={3} md={6}>
              <span
                style={{
                  color: "#1B1B1B",
                  fontWeight: `${
                    tabs === "Ancien membre du Parlement" ? "700" : "400"
                  }`,
                  fontSize: "18px",
                  cursor: "pointer",
                  float: "right",
                  textAlign: "right",
                }}
                onClick={() => setTabs("Ancien membre du Parlement")}
              >
                العضوية السابقة بمجلسي البرلمان
              </span>
            </Col>
          </Row>
          <Row
            style={{
              backgroundColor: "#F3F3F3",
              padding: "20px 20px",
              margin: "20px 0px",
              borderRadius: "10px",
            }}
          >
            <Col lg={2} md={6}>
              <span
                style={{
                  color: "#1B1B1B",
                  fontWeight: `${
                    tabs === "Informations familiales" ? "700" : "400"
                  }`,
                  fontSize: "18px",
                  cursor: "pointer",
                  float: "right",
                  textAlign: "right",
                }}
                onClick={() => setTabs("Informations familiales")}
              >
                معلومات عائلية
              </span>
            </Col>
            <Col lg={2} md={6}>
              <span
                style={{
                  color: "#1B1B1B",
                  fontWeight: `${
                    tabs === "La situation statutaire" ? "700" : "400"
                  }`,
                  fontSize: "18px",
                  cursor: "pointer",
                  float: "right",
                  textAlign: "right",
                }}
                onClick={() => setTabs("La situation statutaire")}
              >
                الوضعية النظامية
              </span>
            </Col>
            <Col lg={2} md={6}>
              <span
                style={{
                  color: "#1B1B1B",
                  fontWeight: `${
                    tabs === "Régime de retraite" ? "700" : "400"
                  }`,
                  fontSize: "18px",
                  cursor: "pointer",
                  float: "right",
                  textAlign: "right",
                }}
                onClick={() => setTabs("Régime de retraite")}
              >
                نظام التقاعد
              </span>
            </Col>
            <Col lg={2} md={6}>
              <span
                style={{
                  color: "#1B1B1B",
                  fontWeight: `${
                    tabs === "Régimes sociaux de mutuelle et d’assurance"
                      ? "700"
                      : "400"
                  }`,
                  fontSize: "18px",
                  cursor: "pointer",
                  float: "right",
                  textAlign: "right",
                }}
                onClick={() =>
                  setTabs("Régimes sociaux de mutuelle et d’assurance")
                }
              >
                الأنظمة الاجتماعية للتعاضد والتأمين
              </span>
            </Col>
            <Col lg={2} md={6}>
              <span
                style={{
                  color: "#1B1B1B",
                  fontWeight: `${tabs === "Régime fiscal" ? "700" : "400"}`,
                  fontSize: "18px",
                  cursor: "pointer",
                  float: "right",
                  textAlign: "right",
                }}
                onClick={() => setTabs("Régime fiscal")}
              >
                النظام الضريبي
              </span>
            </Col>
            <Col lg={2} md={6}>
              <span
                style={{
                  color: "#1B1B1B",
                  fontWeight: `${tabs === "Info finance" ? "700" : "400"}`,
                  fontSize: "18px",
                  cursor: "pointer",
                  float: "right",
                  textAlign: "right",
                }}
                onClick={() => setTabs("Info finance")}
              >
                معلومات مالية
              </span>
            </Col>
          </Row>
        </Col>
      </Row>

      {tabs == "Informations générales" ? (
        <Step1 />
      ) : tabs == "Informations électorales" ? (
        <Step2 />
      ) : tabs == "Niveau d’études" ? (
        <Step3 />
      ) : tabs == "Activité professionnelle de Conseiller parlementaire" ? (
        <Step4 />
      ) : tabs == "Ancien membre du Parlement" ? (
        <Step5 />
      ) : tabs == "Informations familiales" ? (
        <Step6 />
      ) : tabs == "La situation statutaire" ? (
        <Step7 />
      ) : tabs == "Régime de retraite" ? (
        <Step8 />
      ) : tabs == "Régimes sociaux de mutuelle et d’assurance" ? (
        <Step9 />
      ) : tabs == "Régime fiscal" ? (
        <Step10 />
      ) : tabs == "Info finance" ? (
        <Step11 />
      ) : (
        ""
      )}
    </div>
  );
};

export default Details;
