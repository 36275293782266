// import front-end config
import { REHYDRATE } from "redux-persist/es/constants";
import {
  GET_CURRENT_USER_R,
  GET_CURRENT_USER_F,
  SET_CURRENT_USER,
  LOGOUT_S,
  SET_SIDEBAR_OPEN,
  SET_ACTIVE_TAB,
} from "../actionsTypes";

import { UPDATE_THEME_COLOR } from "modules/Theming/actionsTypes";

/**
 * ## InitialState
 *
 * The fields we're concerned with
 */
const initialState = {
  currentUser: null,
  fetchingUser: false,
  sidebarIsOpen: true,
  activeTab: "dashboard",
  color: "primary",
  language: "AR",
};

/**
 * ## Reducer function
 * @param {Object} state - initialState
 * @param {Object} action - type and payload
 */
export default function globalReducer(state = initialState, action) {
  switch (action.type) {
    case REHYDRATE:
      if (action.payload) {
        return {
          ...state,
          sidebarIsOpen: true,
          color: action.payload.app?.color || "primary",
        };
      }
      return state;

    case GET_CURRENT_USER_R:
      return { ...state, fetchingUser: true };

    case GET_CURRENT_USER_F:
      return { ...state, fetchingUser: false };

    case SET_CURRENT_USER:
      return {
        ...state,
        currentUser: action.payload,
        fetchingUser: false,
        isGuest: false,
      };

    case SET_SIDEBAR_OPEN:
      return { ...state, sidebarIsOpen: action.payload };

    case SET_ACTIVE_TAB:
      return { ...state, activeTab: action.payload };

    case UPDATE_THEME_COLOR:
      return { ...state, color: action.payload };

    case LOGOUT_S:
      return initialState;

    default:
      return state;
  }
}
