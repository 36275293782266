import React from "react";
import classNames from "classnames";
import { FormGroup, Input, Label } from "reactstrap";

export default function AppInput({
  label,
  required,
  hasError,
  name,
  placeholder,
  type,
  value,
  onChange,
  pattern,
  ...props
}) {
  return (
    <>
      <FormGroup>
        <Label className="text-black lableInput" for={name}>
          {label}
          {required && <span>*</span>}
        </Label>
        <Input
          className={classNames("custom-input", {
            error: hasError,
          })}
          name={name || `_input`}
          placeholder={placeholder}
          type={type || "text"}
          value={value}
          onChange={(e) => {
            onChange(e.currentTarget.value);
          }}
          autoComplete="off"
          step=".01"
          pattern={pattern}
          {...props}
        />
        {props.leftAddOn && props.leftAddOn}
        <div>
          {props.description !== undefined && (
            <span className="app-input-desc">{props.description}</span>
          )}
        </div>
      </FormGroup>
    </>
  );
}
