import React, { useState } from "react";
import PageDescription from "components/PageDescription";
import { Row, Col, Label, Button, Spinner } from "reactstrap";
import UploadButton from "components/UploadButton";
import AppInput from "components/AppInput";
import DateTimeInput from "components/DateTimeInput";
import nextId from "react-id-generator";
import CustomSimpleSelect from "components/CustomSimpleSelect";
import CustomMultiSelect from "components/CustomMultiSelect";
import { useSelector } from "react-redux";
import { detailsStateSelect } from "../../../selector";
import { LIST_OPTION } from "config/app";
import { useUpdateConseillers } from "../../../hooks/useUpdateConseillers";
const Step4 = () => {
  const { info_activite_pro } = useSelector(detailsStateSelect);
  const { id } = useSelector(detailsStateSelect);

  const [state, setState] = useState({
    nature_activite:
      LIST_OPTION.find(
        (item) => item.value === info_activite_pro?.nature_activite
      ) || "",
    precision_activite: info_activite_pro?.precision_activite || "",
    precision_nature_activite:
      info_activite_pro?.precision_nature_activite || "",
    etablissement: info_activite_pro?.etablissement || "",
    pourcentage_capital: info_activite_pro?.pourcentage_capital || "",
    precision_fonctions_exercer:
      LIST_OPTION.find(
        (item) => item.value === info_activite_pro?.precision_fonctions_exercer
      ) || "",
    autre_fonctions: info_activite_pro?.autre_fonctions || "",
    fonctions_non_electives:
      info_activite_pro.fonctions_non_electives.map((item2) =>
        LIST_OPTION.find((item) => item.value === item2)
      ) || "",
    description_fonctions: info_activite_pro?.description_fonctions || "",
    fonctions_non_electives_renumerees:
      LIST_OPTION.find(
        (item) =>
          item.value === info_activite_pro?.fonctions_non_electives_renumerees
      ) || "",
    etat_organisation: info_activite_pro?.etat_organisation || "",
    fonctions_conseils:
      LIST_OPTION.find(
        (item) => item.value === info_activite_pro?.fonctions_conseils
      ) || "",
    conseil_constitutionnelle:
      info_activite_pro?.conseil_constitutionnelle || "",
    description_fonctions_conseil:
      info_activite_pro?.description_fonctions_conseil || "",
    autres_activites: info_activite_pro?.autres_activites || "",
    employeur: info_activite_pro?.employeur || "",
  });
  console.log(
    "fonctions_non_electives",
    info_activite_pro.fonctions_non_electives_renumerees
  );
  const [photo, setPhoto] = useState(null);

  const [emptyFields, setEmptyFields] = useState(false);

  const [{ fetching, error }, updateConseillers] = useUpdateConseillers(id);
  const onUpdateData = async () => {
    const payload = {
      info_activite_pro: {
        nature_activite: state?.nature_activite?.value,
        precision_activite: state?.precision_activite,
        precision_nature_activite: state?.precision_nature_activite,
        etablissement: state?.etablissement,
        pourcentage_capital: state?.pourcentage_capital,
        precision_fonctions_exercer: state?.precision_fonctions_exercer?.value,
        autre_fonctions: state?.autre_fonctions,
        fonctions_non_electives: state.fonctions_non_electives.map(
          (item2) => item2?.value
        ),
        description_fonctions: state?.description_fonctions,
        fonctions_non_electives_renumerees:
          state?.fonctions_non_electives_renumerees?.value,
        etat_organisation: state?.etat_organisation,
        fonctions_conseils: state?.fonctions_conseils?.value,
        conseil_constitutionnelle: state?.conseil_constitutionnelle,
        description_fonctions_conseil: state?.description_fonctions_conseil,
        autres_activites: state?.autres_activites,
        employeur: state?.employeur,
      },
    };
    updateConseillers(payload);
  };

  const invalidForm =
    !state.nature_activite ||
    (!state.precision_nature_activite &&
      (state.nature_activite?.value === "PUBLIC" ||
        state.nature_activite?.value === "PRIVE")) ||
    (state.nature_activite?.value === "MIXTE" && !state.etablissement) ||
    (state.nature_activite?.value === "MIXTE" && !state.pourcentage_capital) ||
    (state.nature_activite?.value === "MIXTE" &&
      !state.precision_fonctions_exercer) ||
    !state.fonctions_non_electives[0]?.value ||
    !state.fonctions_non_electives_renumerees ||
    !state.fonctions_conseils.value ||
    (state.fonctions_non_electives_renumerees?.value &&
      !state.etat_organisation) ||
    (state.fonctions_conseils.value && !state.conseil_constitutionnelle) ||
    (state.fonctions_conseils.value && !state.employeur);

  return (
    <div>
      <PageDescription title=" النشاط المهني للمستشار (ة) البرلماني (ة) :" />

      <Row>
        <Col xs="12" sm="6">
          <CustomSimpleSelect
            label={"طبيعة النشاط المهني "}
            required
            options={[
              { label: "نشاط أو مهنة حرة", value: "LIBERALE" },
              { label: " القطاع العام", value: "PUBLIC" },
              { label: "  القطاع الخاص", value: "PRIVE" },
              { label: " قطاع الاقتصاد المختلط", value: "MIXTE" },
            ]}
            value={state.nature_activite}
            onSelectValue={(v) => {
              setState({ ...state, nature_activite: v === null ? "" : v });
            }}
          />
        </Col>

        {state.nature_activite.value === "LIBERALE" && (
          <Col xs="12" sm="3">
            <AppInput
              label="تحديد النشاط أو المهنة الحرة"
              type="text"
              hasError={emptyFields && !state.precision_activite}
              value={state.precision_activite}
              onChange={(t) => setState({ ...state, precision_activite: t })}
            />
          </Col>
        )}

        {(state.nature_activite.value === "PUBLIC" ||
          state.nature_activite.value === "PRIVE") && (
          <Col xs="12" sm="3">
            <AppInput
              label="تحديد طبيعة المهام"
              type="text"
              required
              hasError={emptyFields && !state.precision_nature_activite}
              value={state.precision_nature_activite}
              onChange={(t) =>
                setState({ ...state, precision_nature_activite: t })
              }
            />
          </Col>
        )}
      </Row>
      {state.nature_activite.value === "MIXTE" && (
        <>
          <Row>
            <div
              style={{
                textAlign: "right",
                fontWeight: "700",
                marginRight: "16px",
              }}
            >
              بالنسبة للمستشارين (ات) المنتمين(ات) إلى القطاع المختلط، يرجى
              تحديد{" "}
            </div>
          </Row>
          <Row>
            <Col xs="12" sm="4">
              <AppInput
                label="المؤسسة أو المقاولة التي تعملون بها"
                type="text"
                required
                hasError={emptyFields && !state.etablissement}
                value={state.etablissement}
                onChange={(t) => setState({ ...state, etablissement: t })}
              />
            </Col>
            <Col xs="12" sm="4">
              <AppInput
                label="نسبة مساهمة الدولة في رأسمال المقاولة أو المؤسسة المعنية"
                type="text"
                required
                hasError={emptyFields && !state.pourcentage_capital}
                value={state.pourcentage_capital}
                onChange={(t) => setState({ ...state, pourcentage_capital: t })}
              />
            </Col>
          </Row>
          <Row>
            <Col xs="12" sm="4">
              <CustomSimpleSelect
                label={" تحديد طبيعة المهام الموكولة إليكم"}
                required
                options={[
                  { label: " رئيس مجلس الادارة  ", value: "PRESIDENT" },
                  { label: "  مدير عام أو مدير عام منتدب", value: "DIRECTEUR" },
                  { label: "متصرف", value: "ADMINISTRATEUR" },
                  {
                    label: "عضو مجلس الإدارة الجماعية",
                    value: "MEMBRE_DIRECTOIRE",
                  },
                  { label: "عضو مجلس الرقابة", value: "MEMBRE_CONSEIL" },
                ]}
                value={state.precision_fonctions_exercer}
                onSelectValue={(v) => {
                  setState({
                    ...state,
                    precision_fonctions_exercer: v === null ? "" : v,
                  });
                }}
              />
            </Col>
            <Col xs="12" sm="4">
              <AppInput
                label="مهام أخرى (يرجى التحديد) "
                type="text"
                hasError={emptyFields && !state.autre_fonctions}
                value={state.autre_fonctions}
                onChange={(t) => setState({ ...state, autre_fonctions: t })}
              />
            </Col>
          </Row>
        </>
      )}

      <Row>
        <div
          style={{ textAlign: "right", fontWeight: "700", marginRight: "16px" }}
        >
          النشاط المهني للمستشار (ة) البرلماني (ة)
        </div>
      </Row>

      <Row>
        <Col xs="12" sm="6">
          <CustomMultiSelect
            label="هل تزاولون أي مهام عمومية غير انتخابية لدى مصالح الدولة، أو الجماعات الترابية، أو المؤسسات العمومية، أو الاشخاص الاعتباريين من أشخاص القانون العام أو شركات تساهم الدولة بأكثر من 30% من رأسمالها "
            required
            options={[
              { label: "نعم", value: "OUI" },
              { label: "لا", value: "NON" },
              { label: "دائمة", value: "PERMANENTE" },
              { label: "مؤقتة", value: "PROVISOIRE" },
            ]}
            value={state.fonctions_non_electives}
            onSelectValue={(v) => {
              setState({
                ...state,
                fonctions_non_electives: v === null ? "" : v,
              });
            }}
          />
        </Col>
      </Row>
      <Row>
        <Col xs="12" sm="3">
          <AppInput
            label="تحديد المهام (إن وجدت)"
            type="text"
            hasError={emptyFields && !state.description_fonctions}
            value={state.description_fonctions}
            onChange={(t) => setState({ ...state, description_fonctions: t })}
          />
        </Col>
      </Row>
      <Row>
        <Col xs="12" sm="6">
          <CustomSimpleSelect
            label=" هل تؤدون أي مهام غير تمثيلية تؤدي الأجرة عنها دولة أجنبية أو منظمة دولية أو منظمة دولية غير حكومية؟"
            required
            options={[
              { label: "نعم", value: true },
              { label: "لا", value: false },
            ]}
            value={state.fonctions_non_electives_renumerees}
            onSelectValue={(v) => {
              setState({
                ...state,
                fonctions_non_electives_renumerees: v === null ? "" : v,
              });
            }}
          />
        </Col>
        {state.fonctions_non_electives_renumerees?.value && (
          <Col xs="12" sm="3">
            <AppInput
              label=" تحديد الدولة أو المنظمة"
              type="text"
              required
              hasError={emptyFields && !state.etat_organisation}
              value={state.etat_organisation}
              onChange={(t) => setState({ ...state, etat_organisation: t })}
            />
          </Col>
        )}
      </Row>

      <Row>
        <Col xs="12" sm="4">
          <CustomSimpleSelect
            label=" هل تزاولون أية مهام بأحد المجالس أو الهيئات الدستورية ؟"
            required
            options={[
              { label: "نعم", value: true },
              { label: "لا", value: false },
            ]}
            value={state.fonctions_conseils}
            onSelectValue={(v) => {
              setState({ ...state, fonctions_conseils: v === null ? "" : v });
            }}
          />
        </Col>
      </Row>
      {state.fonctions_conseils?.value && (
        <Row>
          <Col xs="12" sm="3">
            <AppInput
              label=" تحديد المجلس أو الهيئة الدستورية "
              type="text"
              required
              hasError={emptyFields && !state.conseil_constitutionnelle}
              value={state.conseil_constitutionnelle}
              onChange={(t) =>
                setState({ ...state, conseil_constitutionnelle: t })
              }
            />
          </Col>
          <Col xs="12" sm="3">
            <AppInput
              label=" تحديد المهام (إن وجدت) "
              type="text"
              hasError={emptyFields && !state.description_fonctions_conseil}
              value={state.description_fonctions_conseil}
              onChange={(t) =>
                setState({ ...state, description_fonctions_conseil: t })
              }
            />
          </Col>
          <Col xs="12" sm="3">
            <AppInput
              label="أنشطة أو مهام أخرى (يرجى التحديد)"
              type="text"
              hasError={emptyFields && !state.autres_activites}
              value={state.autres_activites}
              onChange={(t) => setState({ ...state, autres_activites: t })}
            />
          </Col>
          <Col xs="12" sm="3">
            <AppInput
              label=" تحديد الجهة المشغلة"
              type="text"
              required
              hasError={emptyFields && !state.employeur}
              value={state.employeur}
              onChange={(t) => setState({ ...state, employeur: t })}
            />
          </Col>
        </Row>
      )}
      <Row>
        <Col xs="12" sm="8" md="8" lg="4">
          {error && <h6 className="auth-error">{error?.result?.message}</h6>}
          <div className="creation-footer">
            <Button
              className={` space-left themeColor`}
              onClick={(e) => {
                e.preventDefault();
                onUpdateData();
              }}
              size="lg"
              disabled={invalidForm}
            >
              {fetching ? (
                <Spinner size="sm" />
              ) : (
                <span className="text-bold"> تعديل الوثيقة </span>
              )}
            </Button>
          </div>
        </Col>
      </Row>
    </div>
  );
};

export default Step4;
