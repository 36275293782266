import React, { useContext } from "react";
import { useSelector, useDispatch } from "react-redux";
import { bindActionCreators } from "redux";
import { ThemeContext } from "contextProviders/ThemeProvider";

import * as actions from "../../actions";
import { createStateSelect, currentUserStateSelect } from "../../selector";

import CreateExpeditions from "./component";

const CreateExpeditionsContainer = (props) => {
  const theme = useContext(ThemeContext);
  const create = useSelector(createStateSelect);
  const { roles } = useSelector(currentUserStateSelect) || {};
  const dispatch = useDispatch();
  const actionsDispatch = bindActionCreators({ ...actions }, dispatch);
  return (
    <CreateExpeditions
      userRole={roles?.[0]}
      theme={theme}
      create={create}
      actions={actionsDispatch}
      {...props}
    />
  );
};

export default CreateExpeditionsContainer;
