import React, { useState, useEffect } from "react";

import { Input, Button } from "reactstrap";
import Select from "react-select";

import PageDescription from "components/PageDescription";
import CustomTable from "components/CustomTable";
import EmptyList from "components/EmptyList";
import { FormGroup, Label } from "reactstrap";
import useFetchList from "hooks/useFetchList";

import { conseillersUrl } from "../../api/endpoints";
import * as tableConfig from "./tableConfig";
import CustomAsyncSelect from "components/CustomAsyncSelect";
import CustomSimpleSelect from "components/CustomSimpleSelect";
import "assets/css/listviews.css";
import "../../Conseillers.styles.scss";
import { conseillersByIdUrl } from "../../api/endpoints";
import { Api } from "api";
import Snackbar from "@material-ui/core/Snackbar";
import MuiAlert from "@material-ui/lab/Alert";
import Fade from "@material-ui/core/Fade";

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}
const List = (props) => {
  const [sent, setSent] = useState({ display: false, text: "", error: false });
  const { userRole } = props;
  const [fetchParams, setFecthParams] = useState({ skip: 0, top: 10, $q: "" });
  const [apiEndpoint, setEndpoint] = useState(conseillersUrl(""));
  const { data, count, fetching } = useFetchList(apiEndpoint);
  useEffect(() => {
    const filter = "";
    const urlQuery = `${`&$skip=${fetchParams.skip}&$top=${fetchParams.top}`}${
      fetchParams.q ? "&$q=" + fetchParams.q : ""
    }${filter ? "&$filter=" + filter : "&$filter=" + ""}`;
    setEndpoint(conseillersUrl(urlQuery));
  }, [
    fetchParams.role,
    fetchParams.q,
    fetchParams.skip,
    fetchParams.top,
    userRole,
  ]);

  const fetchElement = async (id) => {
    if (id) {
      const data = await Api().get(conseillersByIdUrl(id));
      props.actions.setCurrentRegistration(data);
      props.history.push("/Conseillers/details");
    } else {
      setSent({
        display: true,
        text: "هذا المستشار لم يقم بملء الإستمارة",
        error: false,
      });
    }
  };

  useEffect(() => {
    setTimeout(() => {
      setSent({ ...sent, display: false });
    }, 9000);
  }, [sent]);

  return (
    <div className="app-main list-Utilisateurs">
      <PageDescription title="قائمة المستشارين" />
      <div className="filters-view">
        <FormGroup className="ml-2 mt-4 ">
          {/* <Label className="text-black">Utilisateur</Label> */}
          <Input
            width="200px"
            placeholder="إسم المستشار"
            type="text"
            name="filter-name"
            className={"filter-input"}
            value={fetchParams.q}
            onChange={(e) => {
              e.preventDefault();
              setFecthParams({
                q: e.currentTarget.value,
                skip: 0,
                top: fetchParams.top,
              });
            }}
          ></Input>
        </FormGroup>

        <FormGroup className="ml-2">
          <Button
            style={{ marginTop: "22px", width: "220px" }}
            className={`btn-round text-bold  ${props.theme}`}
            color="secondary"
            onClick={(e) => {
              e.preventDefault();
              props.history.push("/Conseillers/createplusieur");
            }}
            size="md"
          >
            دعوة المستشارين
          </Button>
        </FormGroup>
      </div>
      <div>
        {data.length === 0 ? (
          <EmptyList fetching={fetching} text={"Aucune donnée"} />
        ) : (
          <CustomTable
            columns={tableConfig.columns}
            dataSource={data}
            cellKey={"id"}
            onRowPress={(item) => {
              // props.actions.setCurrentRegistration(item);
              // props.history.push("/Conseillers/details");
              fetchElement(item?.registration);
            }}
            // rowClickable
            pagination={{
              count,
              rowsPerPage: fetchParams.top,
              page: Math.round(fetchParams.skip / fetchParams.top),
            }}
            handleChangePage={(params) => {
              setFecthParams(params);
            }}
            handleChangeRowsPerPage={(params) => {
              setFecthParams(params);
            }}
          />
        )}
      </div>
      <Snackbar
        open={sent.display}
        onClose={() => setSent({ display: false, text: "", error: false })}
        TransitionComponent={Fade}
        key={Fade.name}
      >
        <Alert
          onClose={() => setSent({ display: false, text: "", error: false })}
          severity={sent.error ? "error" : "info"}
        >
          {sent.text}
        </Alert>
      </Snackbar>
    </div>
  );
};

export default List;
