import { LOGIN_S, SIGNUP_S, FORGOT_PASSWORD_S } from "./actionsTypes";

export const loginSuccess = (payload) => ({
  type: LOGIN_S,
  payload,
});

export const signupSuccess = (payload) => ({
  type: SIGNUP_S,
  payload,
});

export const forgotPasswordSuccess = (payload) => ({
  type: FORGOT_PASSWORD_S,
  payload,
});
