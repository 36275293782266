import { createSelector } from "reselect";

export const currentUserStateSelect = createSelector(
  (state) => state.app,
  (app) => app.currentUser
);

export const listStateSelect = createSelector(
  (state) => state.Document,
  (Document) => Document?.list
);

export const detailsStateSelect = createSelector(
  (state) => state.Document,
  (Document) => Document.details
);

export const createStateSelect = createSelector(
  (state) => state.Document,
  (Document) => Document?.create
);
