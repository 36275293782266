import React, { useState } from "react";
import PageDescription from "components/PageDescription";
import { Row, Col, Label, Button, Spinner } from "reactstrap";
import UploadButton from "components/UploadButton";
import AppInput from "components/AppInput";
import DateTimeInput from "components/DateTimeInput";
import nextId from "react-id-generator";
import CustomSimpleSelect from "components/CustomSimpleSelect";
import { useSelector } from "react-redux";
import { detailsStateSelect } from "../../../selector";
import { LIST_OPTION } from "config/app";
import { useUpdateConseillers } from "../../../hooks/useUpdateConseillers";
const Step9 = () => {
  const { info_regimes_sociaux } = useSelector(detailsStateSelect);
  const { id } = useSelector(detailsStateSelect);
  const [state, setState] = useState({
    regime_affiliation: info_regimes_sociaux?.regime_affiliation || "",
    date_affiliation: info_regimes_sociaux?.date_affiliation || "",
    num_affiliation: info_regimes_sociaux?.num_affiliation || "",
    annees_affiliation: info_regimes_sociaux?.annees_affiliation || "",
    num_immatriculation: info_regimes_sociaux?.num_immatriculation || "",
    num_adhesion: info_regimes_sociaux?.num_adhesion || "",
  });

  
  const [{ fetching, error }, updateConseillers] = useUpdateConseillers(id);
  const onUpdateData = async () => {
    const payload = {
      info_regimes_sociaux: {
        regime_affiliation: state?.regime_affiliation,
        date_affiliation: state?.date_affiliation,
        num_affiliation: state?.num_affiliation,
        annees_affiliation: state?.annees_affiliation,
        num_immatriculation: state?.num_immatriculation,
        num_adhesion: state?.num_adhesion,
      },
    };
    updateConseillers(payload);
  };

  const [emptyFields, setEmptyFields] = useState(false);

  const invalidForm =
  !state.regime_affiliation||
  !state.date_affiliation||
  !state.num_affiliation||
  !state.annees_affiliation||
  !state.num_immatriculation||
  !state.num_adhesion;

  return (
    <div>
      <PageDescription title="   الأنظمة الاجتماعية للتعاضد والتأمين :" />

      <Row>
        <Col xs="12" sm="4">
          <AppInput
            required
            label="النظام المنخرط فيه"
            type="text"
            hasError={emptyFields && !state.regime_affiliation}
            value={state.regime_affiliation}
            onChange={(t) => setState({ ...state, regime_affiliation: t })}
          />
        </Col>
        <Col xs="12" sm="4">
          <DateTimeInput
            label="تاريخ الانخراط "
            hasError={emptyFields && !state.date_affiliation}
            required
            minDate={true}
            value={state.date_affiliation}
            onChange={(e) => {
              setState({
                ...state,
                date_affiliation: e,
              });
            }}
          />
        </Col>
        <Col xs="12" sm="4">
          <AppInput
            required
            label="رقم الانخراط"
            type="text"
            hasError={emptyFields && !state.num_affiliation}
            value={state.num_affiliation}
            onChange={(t) => setState({ ...state, num_affiliation: t })}
          />
        </Col>
      </Row>
      <Row>
        <Col xs="12" sm="4">
          <AppInput
            required
            label="عدد سنوات الانخراط"
            type="text"
            hasError={emptyFields && !state.annees_affiliation}
            value={state.annees_affiliation}
            onChange={(t) => setState({ ...state, annees_affiliation: t })}
          />
        </Col>
        <Col xs="12" sm="4">
          <AppInput
            required
            label="رقم التسجيل"
            type="text"
            hasError={emptyFields && !state.num_immatriculation}
            value={state.num_immatriculation}
            onChange={(t) => setState({ ...state, num_immatriculation: t })}
          />
        </Col>
        <Col xs="12" sm="4">
          <AppInput
            required
            label=" رقم الانضمام/الانتساب (للمشغل) "
            type="text"
            hasError={emptyFields && !state.num_adhesion}
            value={state.num_adhesion}
            onChange={(t) => setState({ ...state, num_adhesion: t })}
          />
        </Col>
      </Row>
      <Row>
        <Col xs="12" sm="8" md="8" lg="4">
          {error && <h6 className="auth-error">{error?.result?.message}</h6>}
          <div className="creation-footer">
            <Button
              className={` space-left themeColor`}
              onClick={(e) => {
                e.preventDefault();
                onUpdateData();
              }}
              size="lg"
              disabled={invalidForm}
            >
              {fetching ? (
                <Spinner size="sm" />
              ) : (
                <span className="text-bold"> تعديل الوثيقة </span>
              )}
            </Button>
          </div>
        </Col>
      </Row>
    </div>
  );
};

export default Step9;
