export const SET_CURRENT_USER = "SET_CURRENT_USER";
export const SET_USER_GUEST = "SET_USER_GUEST";

export const LOGOUT_S = "LOGOUT_S";

export const GET_CURRENT_USER_R = "GET_CURRENT_USER_R";
export const GET_CURRENT_USER_F = "GET_CURRENT_USER_F";

export const SET_SIDEBAR_OPEN = "SET_SIDEBAR_OPEN";
export const SET_ACTIVE_TAB = "SET_ACTIVE_TAB";
