import React, { useContext, useState } from "react";
import { useDispatch } from "react-redux";
import { push } from "connected-react-router";

import { Modal, ModalBody, Button } from "reactstrap";

import { ThemeContext } from "contextProviders/ThemeProvider";

import { Api } from "api";

const ModalDelete = (props) => {
  const theme = useContext(ThemeContext);
  const [fetching, setFecthing] = useState(false);
  const dispatch = useDispatch();

  const onDelete = async () => {
    try {
      setFecthing(true);
      await Api().delete(`/admin/documents/${props.id}`);
      setFecthing(false);
      dispatch(push("/document/list"));
    } catch {
      setFecthing(false);
    } finally {
      setFecthing(false);
      dispatch(push("/document/list"));
    }
  };

  return (
    <Modal
      isOpen={props.isOpen}
      toggle={() => {
        if (!fetching) {
          props.onCloseModal();
        }
      }}
    >
      <div className="modal-header">
        <h5 className="modal-title"> حدف الوثيقة</h5>
        <button
          onClick={(e) => {
            if (!fetching) {
              props.onCloseModal();
            }
          }}
          aria-label="Close"
          className="close"
          type="button"
        >
          <span aria-hidden={true}>×</span>
        </button>
      </div>
      <ModalBody>
        <p>هل أنت متأكد أنك تريد حدف الوثيقة؟ </p>
      </ModalBody>
      <div className="modal-footer logout">
        <Button
          disabled={fetching}
          className="btn-round logout"
          onClick={(e) => {
            e.preventDefault();
            props.onCloseModal();
          }}
          color="secondary"
          type="button"
        >
          لا
        </Button>
        <Button
          disabled={fetching}
          className={`btn-round logout ${theme}`}
          color="default"
          type="button"
          onClick={onDelete}
        >
          نعم
        </Button>
      </div>
    </Modal>
  );
};

export default ModalDelete;
