import List from "./containers/list";
import Details from "./containers/details";
import CreateStep1 from "./containers/create";

const routes = [
  {
    path: "/list",
    component: List,
    exact: true,
  },
  {
    path: "/details",
    component: Details,
    exact: true,
  },
  {
    path: "/create",
    component: CreateStep1,
    exact: true,
  },
];

export default routes;
