// routes components
import Login from "./containers/login";
import Signup from "./containers/signup";
import Forgot from "./containers/forgotPassword";
import SignupSuccess from "./containers/signupSuccess";
import ForgotSuccess from "./containers/forgotSuccess";

const routes = [
  {
    path: "/signin",
    component: Login,
    exact: true,
  },
  {
    path: "/signup",
    component: Signup,
    exact: true,
  },
  {
    path: "/forgot",
    component: Forgot,
    exact: true,
  },
  {
    path: "/success",
    component: SignupSuccess,
    exact: true,
  },
  {
    path: "/forgotSuccess",
    component: ForgotSuccess,
    exact: true,
  },
];

export default routes;
