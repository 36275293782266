import React from "react";
import classNames from "classnames";

import Avatar from "@material-ui/core/Avatar";

import "assets/css/uploadButton.css";

const UploadButton = ({
  setFileUrl,
  rmFileUrl,
  file,
  hasError,
  url,
  id,
  ...props
}) => {
  console.log("url", url);
  return (
    <div className="btn-upload">
      <div
        onClick={() => {
          if (!props.disabled) {
            document.getElementById(id).click();
          }
        }}
        className={classNames("btn-upload-container", {
          error: hasError,
        })}
      >
        {file ? (
          <Avatar
            variant="rounded"
            alt="logo"
            src={file.url ? file.url : url}
          />
        ) : (
          <span>تحميل الصورة</span>
        )}
      </div>
      {file && !props.withoutRemove && (
        <img
          alt="x"
          src={require("assets/img/rm-icon.png")}
          className="pack-photo-remove"
          onClick={() => rmFileUrl()}
        />
      )}
      <input
        className="file-input-hidden"
        type="file"
        id={id}
        accept="image/*"
        onChange={(e) => {
          // e.preventDefault();

          const file = e.target.files[0];
          console.log("file", file);
          const url = URL.createObjectURL(file);

          const reader = new FileReader();
          reader.onloadend = () => {
            setFileUrl({ file, url });
          };

          reader.onerror = (e) => {
            console.log("e", e);
          };

          reader.readAsDataURL(file);
          e.target.value = null;
        }}
      />
    </div>
  );
};

export default UploadButton;
