import React, { useState, useEffect } from "react";
import { array, string, func, number, shape } from "prop-types";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TablePagination,
} from "@material-ui/core";
import { pick } from "lodash";

import "assets/css/customTable.css";

CustomTable.defaultProps = {
  withPagination: true,
  cellKey: "_id",
};

CustomTable.propTypes = {
  columns: array,
  dataSource: array,
  cellKey: string,
  onRowPress: func,
  pagination: shape({
    count: number,
    rowsPerPage: number,
    page: number,
  }),
};

export default function CustomTable(props) {
  const {
    columns,
    dataSource,
    cellKey,
    withPagination,
    pagination,
    onRowPress,
    handleChangePage,
    handleChangeRowsPerPage,
  } = props;
  const [titles, setTitles] = useState([]);
  const [sent, setSent] = useState(false);

  useEffect(() => {
    const columnTitles = columns.map((c) => c.title);
    setTitles(columnTitles);
  }, [columns]);

  const renderCells = (cellData, clickCb, sent, setSent) => {
    return columns.map((column, i) => {
      let cellInfo = [column.dataIndex];
      if (Array.isArray(column.dataIndex)) {
        cellInfo = column.dataIndex;
      }
      if (column.dataIndex.includes(".")) {
        cellInfo = [column.dataIndex.split(".")[0]];
      }

      return (
        <TableCell key={`${i}-${cellData[cellKey]}`}>
          {column.renderItem(
            pick(cellData, cellInfo),
            {
              item: cellData[cellKey],
            },
            clickCb,
            pagination.count
          )}
        </TableCell>
      );
    });
  };

  const handlePage = (e, page) => {
    const top = pagination.rowsPerPage;
    const skip = page * pagination.rowsPerPage;
    handleChangePage({ top, skip });
  };

  const handleRowsPerPage = (e) => {
    const rowsPerPage = e.target.value;
    const top = rowsPerPage;
    const skip = 0;
    handleChangeRowsPerPage({ top, skip });
  };

  const handleClick = (event, item) => {
    onRowPress(item);
  };

  return (
    <div className="custom-table">
      <Table className="table-global-bordered">
        <TableHead className="thead table">
          <TableRow hover>
            {titles.map((t, i) => (
              <TableCell className={`${"table-title"} ${t}`} key={`${i}_${t}`}>
                {t}
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {dataSource.map((data) => {
            return (
              <TableRow
                hover
                onClick={(event) => {
                  if (props.rowClickable) {
                    handleClick(event, data);
                  }
                }}
                key={data[cellKey]}
              >
                {renderCells(data, (event) => handleClick(event, data))}
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
      {withPagination && (
        <TablePagination
          rowsPerPageOptions={[5, 10, 25]}
          component="div"
          count={pagination.count}
          rowsPerPage={pagination.rowsPerPage}
          page={pagination.page}
          backIconButtonProps={{
            "aria-label": "Précédent",
          }}
          nextIconButtonProps={{
            "aria-label": "Suivant",
          }}
          labelDisplayedRows={({ from, to, count }) =>
            `${pagination.rowsPerPage}-${pagination.page} من ${pagination.count}`
          }
          labelRowsPerPage="عدد الصفوف في الصفحة:"
          onChangePage={handlePage}
          onChangeRowsPerPage={handleRowsPerPage}
        />
      )}
    </div>
  );
}
