export function documentUrl(params) {
  return params && `/admin/documents/?${params}&$sort={ "pos": "ASC"}`;
}

export function createDocumentUrl(role) {
  return `/admin/documents/`;
}
export function documentByIdUrl(id) {
  return `/admin/documents/${id}`;
}
