import React, { useState } from "react";

import { Row, Col, Label, Button, Spinner, FormGroup, Input } from "reactstrap";

import PageDescription from "components/PageDescription";
import AppInput from "components/AppInput";
import CustomSimpleSelect from "components/CustomSimpleSelect";
import CustomAsyncSelect from "components/CustomAsyncSelect";
import { useCreateConseillers } from "../../hooks/useCreateConseillers";
import Switch from "../../../../components/Switch";
import { SettingsEthernet } from "@material-ui/icons";
import { useDispatch } from "react-redux";
import { push } from "connected-react-router";
import { useSelector } from "react-redux";
import { currentUserStateSelect } from "../../selector";
const Create = (props) => {
  const [state, setState] = useState({});
  const [{ fetching, error }, createConseillers] = useCreateConseillers();
  const { role } = useSelector(currentUserStateSelect) || {};

  const invalidForm = !state.origine || !state.fullName || !state.destinataire;

  const onCreate = () => {
    props.actions.setCurrentConseillers(state);
    dispatch(push("/conseillers/list"));
  };

  console.log(props.create);
  const handleChange = (event) => {
    event.persist();
    const name = event.target.name;
    const is_checked = event.target.checked;
    setState({ ...state, name: is_checked });
  };
  const dispatch = useDispatch();
  const handleChangeNameFile = (event) => {
    setState({
      ...state,
      fileName: event.target?.value,
      file: event.target.files[0],
    });
  };
  return (
    <div className="app-main">
      <PageDescription title="وثيقة جديدة" />
      <div className="creation-form">
        <Row>
          <Col xs="12" sm="4">
            <AppInput
              label="إسم الوثيقة"
              required
              value={state.origine}
              onChange={(t) => setState({ ...state, origine: t })}
            />
          </Col>
        </Row>

        <Row>
          <Col xs="12" sm="4" className="InputUpload">
            <label
              style={{ cursor: "pointer", width: "100%" }}
              className="mt-2"
              name="file"
              for="file"
            >
              <div>
                <span className="lableInput">إختيار الوثيقة (pdf)*</span>
                <div className="custom-input">
                  <span>{state.fileName}</span>
                  <div className="input-addon">
                    <img alt="+" src={require("assets/img/iconPdf.png")} />
                  </div>
                </div>
              </div>
              <input
                style={{ display: "none" }}
                id="file"
                type="file"
                name="file"
                accept=".xlsx, .xls, .csv"
                onChange={(file) => {
                  handleChangeNameFile(file);
                  // handleChangeTypeFile(item.id, file);
                }}
              />
            </label>
          </Col>
        </Row>
        <Row>
          <Col xs="12" sm="4" className="InputUpload">
            <label
              style={{ cursor: "pointer", width: "100%" }}
              className="mt-2"
              name="file"
              for="file"
            >
              <div>
                <span className="lableInput">إختيار الأيقونة (png)*</span>
                <div className="custom-input">
                  <span>{state.fileName}</span>
                  <div className="input-addon">
                    <img alt="+" src={require("assets/img/iconPdf.png")} />
                  </div>
                </div>
              </div>
              <input
                style={{ display: "none" }}
                id="file"
                type="file"
                name="file"
                accept=".xlsx, .xls, .csv"
                onChange={(file) => {
                  handleChangeNameFile(file);
                  // handleChangeTypeFile(item.id, file);
                }}
              />
            </label>
          </Col>
        </Row>

        <Row>
          <Col xs="12" sm="4">
            {error && <h6 className="auth-error">{error?.result?.message}</h6>}
            <div className="creation-footer">
              <Button
                className={` space-left ${props.theme}`}
                onClick={(e) => {
                  e.preventDefault();
                  onCreate();
                }}
                size="lg"
                disabled={invalidForm}
              >
                {fetching ? (
                  <Spinner size="sm" />
                ) : (
                  <span className="text-bold"> حفظ الوثيقة </span>
                )}
              </Button>
            </div>
          </Col>
        </Row>
      </div>
    </div>
  );
};

export default Create;
