export function conseillersUrl(params) {
  return params && `/admin/users/?${params}&$sort={ "updated_at": "DESC"}`;
}

export function createConseillersUrl(role) {
  return `/${role}/admins/`;
}
export function conseillersByIdUrl(id) {
  return `/admin/registrations/${id}`;
}

export function inviteByIdUrl(id) {
  return `/admin/users/${id}/invite`;
}

export function uploadFileUrl() {
  return "/admin/users/bulk";
}
