import { useState } from "react";
import { useDispatch } from "react-redux";
import { push } from "connected-react-router";

// helpers
import reportError from "lib/errorHandler";

// api
import { Api } from "api";

// actions
import { uploadFiles } from "actions";

// endpoints
import { createConseillersUrl } from "../api/endpoints";

export function useCreateConseillers() {
  const [fetching, setFetching] = useState(false);
  const [error, setError] = useState(null);
  const dispatch = useDispatch();

  async function createConseillers(role, payload, uploadPayload) {
    try {
      setFetching(true);
      let logoData;
      if (uploadPayload) {
        logoData = await uploadFiles(uploadPayload);
      }
      await Api().post(createConseillersUrl(role, payload.role), {
        ...payload,
        ...(logoData && { image: logoData.file }),
      });
      dispatch(push("/conseillers/list"));
    } catch (error) {
      reportError("createProduct error", error);
      setError(error);
      setFetching(false);
    } finally {
      // setFetching(false);
    }
  }

  return [{ fetching, error }, createConseillers];
}
