import React from "react";
import classNames from "classnames";
import { FormGroup, Label } from "reactstrap";
import Datetime from "react-datetime";

import moment from "lib/moment";

const DateTimeInput = ({ label, required, value, onChange }) => {
  const today = moment(); //.subtract(0, 'days');
  const validDate = function (current) {
    return current.isBefore(today);
  };

  return (
    <FormGroup>
      <Label className="lableInput">
        {label}
        {required && <span>*</span>}
      </Label>
      <Datetime
        className="creation-date"
        inputProps={{
          className: classNames("custom-input", {
            // matriculeLast: props.className === 'matricule-last',
            error: value && new Date(value) >= new Date(),
          }),
          placeholder: "",
        }}
        value={value ? moment(value).format("L") : ""}
        closeOnSelect
        onChange={onChange}
        timeFormat={false}
        isValidDate={validDate}
      />
    </FormGroup>
  );
};

export default DateTimeInput;
