import React, { useState, useEffect } from "react";
import PageDescription from "components/PageDescription";
import { Row, Col, Label, Button, Spinner } from "reactstrap";
import UploadButton from "components/UploadButton";
import AppInput from "components/AppInput";
import DateTimeInput from "components/DateTimeInput";
import nextId from "react-id-generator";
import CustomSimpleSelect from "components/CustomSimpleSelect";
import { useSelector } from "react-redux";
import { detailsStateSelect } from "../../../selector";
import { LIST_OPTION } from "config/app";
import { useUpdateConseillers } from "../../../hooks/useUpdateConseillers";
const Step5 = () => {
  const { info_ancien_membre } = useSelector(detailsStateSelect);
  const { id } = useSelector(detailsStateSelect);
  const [state, setState] = useState({
    mandats_chambre_conseillers: [],
    mandats_chambre_representants: [],
  });

  const [{ fetching, error }, updateConseillers] = useUpdateConseillers(id);

  const onUpdateData = async () => {
    const chambre_representants = state.mandats_chambre_representants.map(
      (item) => ({
        mandat: item.name,
        adhesion_bureau:
          item?.value?.value === "adhesion_bureau" ? true : false,
        presidence_comite:
          item?.value?.value === "presidence_comite" ? true : false,
        membre_equipe: item?.value?.value === "membre_equipe" ? true : false,
      })
    );

    const chambre_conseillers = state.mandats_chambre_conseillers.map(
      (item) => ({
        mandat: item.name,
        adhesion_bureau:
          item?.value?.value === "adhesion_bureau" ? true : false,
        presidence_comite:
          item?.value?.value === "presidence_comite" ? true : false,
        membre_equipe: item?.value?.value === "membre_equipe" ? true : false,
      })
    );
    const payload = {
      info_ancien_membre: {
        mandats_chambre_representants: chambre_representants,
        mandats_chambre_conseillers: chambre_conseillers,
      },
    };
    updateConseillers(payload);
    console.log(payload);
  };

  console.log("info_ancien_membre", info_ancien_membre);

  useEffect(() => {
    const newArray = [];
    info_ancien_membre.mandats_chambre_representants.map((item) =>
      newArray.push({
        id: item._id,
        name: item.mandat,
        value: item.adhesion_bureau
          ? { label: "عضوية المكتب", value: "adhesion_bureau", id: item._id }
          : item.membre_equipe
          ? {
              label: " عضو فريق أو مجموعة",
              value: "membre_equipe",
              id: item._id,
            }
          : item.presidence_comite
          ? { label: "رئاسة لجنة", value: "presidence_comite", id: item._id }
          : "",
        list: [
          { label: "عضوية المكتب", value: "adhesion_bureau", id: item._id },
          {
            label: " عضو فريق أو مجموعة",
            value: "membre_equipe",
            id: item._id,
          },
          { label: "رئاسة لجنة", value: "presidence_comite", id: item._id },
        ],
        listServer: item,
      })
    );

    const newArray2 = [];

    info_ancien_membre.mandats_chambre_conseillers.map((item) =>
      newArray2.push({
        id: item._id,
        name: item.mandat,
        value: item.adhesion_bureau
          ? { label: "عضوية المكتب", value: "adhesion_bureau", id: item._id }
          : item.membre_equipe
          ? {
              label: " عضو فريق أو مجموعة",
              value: "membre_equipe",
              id: item._id,
            }
          : item.presidence_comite
          ? { label: "رئاسة لجنة", value: "presidence_comite", id: item._id }
          : "",
        list: [
          { label: "عضوية المكتب", value: "adhesion_bureau", id: item._id },
          {
            label: " عضو فريق أو مجموعة",
            value: "membre_equipe",
            id: item._id,
          },
          { label: "رئاسة لجنة", value: "presidence_comite", id: item._id },
        ],
        listServer: item,
      })
    );

    setState({
      ...state,
      mandats_chambre_representants: newArray,
      mandats_chambre_conseillers: newArray2,
    });
  }, []);

  const onChangeConseillers = (v) => {
    const newArray = [];
    state.mandats_chambre_conseillers.map((item) => {
      if (item.id === v.id) {
        newArray.push({
          id: item.id,
          name: item.name,
          value: v,
          list: [
            {
              label: "عضوية المكتب",
              value: "adhesion_bureau",
              id: item.id,
            },
            {
              label: " عضو فريق أو مجموعة",
              value: "membre_equipe",
              id: item.id,
            },
            {
              label: "رئاسة لجنة",
              value: "presidence_comite",
              id: item.id,
            },
          ],
          listServer: item,
        });
      } else {
        newArray.push(item);
      }
    });
    console.log("newArray", newArray);

    setState({
      ...state,
      mandats_chambre_conseillers: newArray,
    });
  };

  const onChangeRepresentants = (v) => {
    const newArray = [];
    state.mandats_chambre_representants.map((item) => {
      if (item.id === v.id) {
        newArray.push({
          id: item.id,
          name: item.name,
          value: v,
          list: [
            {
              label: "عضوية المكتب",
              value: "adhesion_bureau",
              id: item.id,
            },
            {
              label: " عضو فريق أو مجموعة",
              value: "membre_equipe",
              id: item.id,
            },
            {
              label: "رئاسة لجنة",
              value: "presidence_comite",
              id: item.id,
            },
          ],
          listServer: item,
        });
      } else {
        newArray.push(item);
      }
    });
    console.log("newArray", newArray);

    setState({
      ...state,
      mandats_chambre_representants: newArray,
      // mandats_chambre_conseillers: newArray,
    });
  };

  console.log("mandats_chambre_conseiller", info_ancien_membre);
  console.log("state", state);
  return (
    <div>
      <PageDescription title="  العضوية السابقة بمجلسي البرلمان :" />

      <Row>
        {state.mandats_chambre_representants
          ? state.mandats_chambre_representants.map((item) => (
              <Col xs="12" sm="3">
                <Row>
                  <div
                    style={{
                      textAlign: "right",
                      fontWeight: "700",
                      marginRight: "16px",
                    }}
                  >
                    مجلس النواب
                  </div>
                </Row>
                <CustomSimpleSelect
                  label={` الولاية التشريعية ${item.name}`}
                  options={item.list}
                  value={item.value}
                  isClearable={false}
                  onSelectValue={(v) => {
                    onChangeRepresentants(v);
                  }}
                />
              </Col>
            ))
          : ""}
      </Row>

      <Row>
        {state.mandats_chambre_conseillers
          ? state.mandats_chambre_conseillers.map((item) => (
              <Col xs="12" sm="3">
                <div style={{ textAlign: "right", fontWeight: "700" }}>
                  مجلس المستشارين
                </div>
                <CustomSimpleSelect
                  label={` الولاية التشريعية ${item.name}`}
                  options={item.list}
                  value={item.value}
                  isClearable={false}
                  onSelectValue={(v) => {
                    onChangeConseillers(v);
                  }}
                />
              </Col>
            ))
          : ""}
      </Row>
      <Row>
        <Col xs="12" sm="8" md="8" lg="4">
          {error && <h6 className="auth-error">{error?.result?.message}</h6>}
          <div className="creation-footer">
            <Button
              className={` space-left themeColor`}
              onClick={(e) => {
                e.preventDefault();
                onUpdateData();
              }}
              size="lg"
              // disabled={invalidForm}
            >
              {fetching ? (
                <Spinner size="sm" />
              ) : (
                <span className="text-bold"> تعديل الوثيقة </span>
              )}
            </Button>
          </div>
        </Col>
      </Row>
    </div>
  );
};

export default Step5;
