import { useState } from "react";
import { useDispatch } from "react-redux";
import { push } from "connected-react-router";

// helpers
import reportError from "lib/errorHandler";

// api
import { Api } from "api";

// actions
import { uploadFiles } from "actions";

// endpoints
import { conseillersByIdUrl } from "../api/endpoints";

export function useUpdateConseillers(id) {
  const [fetching, setFetching] = useState(false);
  const [error, setError] = useState(null);
  const dispatch = useDispatch();

  async function updateConseillers(payload, uploadPayload) {
    try {
      setFetching(true);

      await Api().put(conseillersByIdUrl(id), {
        ...payload,
      });
      dispatch(push("/conseillers/list"));
    } catch (error) {
      reportError("updateConseillers error", error);
      setError(error);
      setFetching(false);
    } finally {
      //   setFetching(false);
    }
  }

  return [{ fetching, error }, updateConseillers];
}
