import { useState } from "react";
import { useDispatch } from "react-redux";
import { push } from "connected-react-router";

// helpers
import reportError from "lib/errorHandler";

// api
import { Api } from "api";

// actions
import { uploadFiles } from "actions";

// endpoints
import { documentByIdUrl } from "../api/endpoints";

export function useUpdateDocument(id) {
  const [fetching, setFetching] = useState(false);
  const [error, setError] = useState(null);
  const dispatch = useDispatch();

  async function updateDocument(payload, uploadPayload) {
    try {
      let logoData;
      if (uploadPayload) {
        logoData = await uploadFiles(uploadPayload);
      }
      await Api().put(documentByIdUrl(id), {
        ...payload,
        ...(logoData && { image: logoData.file }),
      });
      setFetching(true);
      dispatch(push("/Document/list"));
    } catch (error) {
      reportError("updateDocument error", error);
      setError(error);
      setFetching(false);
    } finally {
      //   setFetching(false);
    }
  }

  return [{ fetching, error }, updateDocument];
}
