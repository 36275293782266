import React, { useState } from "react";
import PageDescription from "components/PageDescription";
import { Row, Col, Label, Button, Spinner } from "reactstrap";
import UploadButton from "components/UploadButton";
import AppInput from "components/AppInput";
import DateTimeInput from "components/DateTimeInput";
import nextId from "react-id-generator";
import CustomSimpleSelect from "components/CustomSimpleSelect";
import { useSelector } from "react-redux";
import { detailsStateSelect } from "../../../selector";
import { LIST_OPTION } from "config/app";
import { useUpdateConseillers } from "../../../hooks/useUpdateConseillers";
const Step6 = () => {
  const { info_famille } = useSelector(detailsStateSelect);
  const { id } = useSelector(detailsStateSelect);
  const [state, setState] = useState({
    nom_prenom_conjoint: info_famille?.nom_prenom_conjoint || "",
    date_naissance_conjoint: info_famille?.date_naissance_conjoint || "",
    nationalite_actuelle_conjoint:
      info_famille?.nationalite_actuelle_conjoint || "",
    nationalite_naissance_conjoint:
      info_famille?.nationalite_naissance_conjoint || "",
    profession_conjoint: info_famille?.profession_conjoint || "",
    nom_prenom_pere_conjoint: info_famille?.nom_prenom_pere_conjoint || "",
    nom_prenom_mere_conjoint: info_famille?.nom_prenom_mere_conjoint || "",
    allocations_familiales_conjoint:
      LIST_OPTION.find(
        (item) => item.value === info_famille?.allocations_familiales_conjoint
      ) || "",
    nombre_enfants: info_famille?.nombre_enfants || "",
  });

  const [emptyFields, setEmptyFields] = useState(false);

  const [{ fetching, error }, updateConseillers] = useUpdateConseillers(id);
  const onUpdateData = async () => {
    const payload = {
      info_famille: {
        nom_prenom_conjoint: state?.nom_prenom_conjoint,
        date_naissance_conjoint: state?.date_naissance_conjoint,
        nationalite_actuelle_conjoint: state?.nationalite_actuelle_conjoint,
        nationalite_naissance_conjoint: state?.nationalite_naissance_conjoint,
        profession_conjoint: state?.profession_conjoint,
        nom_prenom_pere_conjoint: state?.nom_prenom_pere_conjoint,
        nom_prenom_mere_conjoint: state?.nom_prenom_mere_conjoint,
        allocations_familiales_conjoint:
          state?.allocations_familiales_conjoint?.value,
        nombre_enfants: state?.nombre_enfants,
      },
    };
    updateConseillers(payload);
  };

  return (
    <div>
      <PageDescription title="  معلومات عائلية :" />
      <Row>
        <div
          style={{ textAlign: "right", fontWeight: "700", marginRight: "16px" }}
        >
          الزوج (إن وجد)
        </div>
      </Row>
      <Row>
        <Col xs="12" sm="4">
          <AppInput
            label=" إسم الزوج"
            type="text"
            hasError={emptyFields && !state.nom_prenom_conjoint}
            value={state.nom_prenom_conjoint}
            onChange={(t) => setState({ ...state, nom_prenom_conjoint: t })}
          />
        </Col>
      </Row>
      <Row>
        <Col xs="12" sm="4">
          <DateTimeInput
            label="  تاريخ ومكان ولادته (ا)"
            hasError={emptyFields && !state.date_naissance_conjoint}
            minDate={true}
            value={state.date_naissance_conjoint}
            onChange={(e) => {
              setState({
                ...state,
                date_naissance_conjoint: e,
              });
            }}
          />
        </Col>

        <Col xs="12" sm="4">
          <AppInput
            label="الجنسية الحالية"
            type="text"
            hasError={emptyFields && !state.nationalite_actuelle_conjoint}
            value={state.nationalite_actuelle_conjoint}
            onChange={(t) =>
              setState({ ...state, nationalite_actuelle_conjoint: t })
            }
          />
        </Col>
        <Col xs="12" sm="4">
          <AppInput
            label="  الجنسية لدى الولادة (إن كانت مختلفة)"
            type="text"
            hasError={emptyFields && !state.nationalite_naissance_conjoint}
            value={state.nationalite_naissance_conjoint}
            onChange={(t) =>
              setState({ ...state, nationalite_naissance_conjoint: t })
            }
          />
        </Col>
      </Row>
      <Row>
        <Col xs="12" sm="4">
          <AppInput
            label="المهنة"
            type="text"
            hasError={emptyFields && !state.profession_conjoint}
            value={state.profession_conjoint}
            onChange={(t) => setState({ ...state, profession_conjoint: t })}
          />
        </Col>
        <Col xs="12" sm="4">
          <AppInput
            label="إسم أب الزوج"
            type="text"
            hasError={emptyFields && !state.nom_prenom_pere_conjoint}
            value={state.nom_prenom_pere_conjoint}
            onChange={(t) =>
              setState({ ...state, nom_prenom_pere_conjoint: t })
            }
          />
        </Col>
        <Col xs="12" sm="4">
          <AppInput
            label=" إسم أم الزوج"
            type="text"
            hasError={emptyFields && !state.nom_prenom_mere_conjoint}
            value={state.nom_prenom_mere_conjoint}
            onChange={(t) =>
              setState({ ...state, nom_prenom_mere_conjoint: t })
            }
          />
        </Col>
      </Row>

      <Row>
        <Col xs="12" sm="4">
          <CustomSimpleSelect
            label="هل يستفيد لزوج من التعويضات العائلية ؟"
            options={[
              { label: "نعم", value: true },
              { label: "لا", value: false },
            ]}
            value={state.allocations_familiales_conjoint}
            onSelectValue={(v) => {
              setState({
                ...state,
                allocations_familiales_conjoint: v === null ? "" : v,
              });
            }}
          />
        </Col>
      </Row>
      <Row>
        <div
          style={{ textAlign: "right", fontWeight: "700", marginRight: "16px" }}
        >
          الأولاد والمتكفل بهم
        </div>
      </Row>
      <Row>
        <Col xs="12" sm="4">
          <AppInput
            label=" العدد"
            type="text"
            hasError={emptyFields && !state.nombre_enfants}
            value={state.nombre_enfants}
            onChange={(t) => setState({ ...state, nombre_enfants: t })}
          />
        </Col>
      </Row>
      <Row>
        <Col xs="12" sm="8" md="8" lg="4">
          {error && <h6 className="auth-error">{error?.result?.message}</h6>}
          <div className="creation-footer">
            <Button
              className={` space-left themeColor`}
              onClick={(e) => {
                e.preventDefault();
                onUpdateData();
              }}
              size="lg"
              // disabled={invalidForm}
            >
              {fetching ? (
                <Spinner size="sm" />
              ) : (
                <span className="text-bold"> تعديل الوثيقة </span>
              )}
            </Button>
          </div>
        </Col>
      </Row>
    </div>
  );
};

export default Step6;
