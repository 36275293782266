/*

=========================================================
* AGMA - v0.1.0
=========================================================

* Copyright 2020 FlexiApps
*/
import React from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import { ConnectedRouter } from "connected-react-router";
import { PersistGate } from "redux-persist/integration/react";
import { ThemeProvider as ThemeP } from "contextProviders/ThemeProvider";

// styles for this kit

import "assets/css/app.css";
import "assets/css/AppInputGroup.css";
import "assets/css/index.css";
import "assets/css/theme.css";
import "assets/css/bootstrap.min.css";
import "assets/scss/now-ui-kit.scss?v=1.4.0";
import "assets/demo/demo.css?v=1.4.0";
import "assets/demo/react-demo.css?v=1.4.0";
import "assets/demo/nucleo-icons-page-styles.css?v=1.4.0";
import "react-datetime/css/react-datetime.css";

import configureSentry from "config/sentryConfig";
// import { RTLProvider } from "contexts/RTLProvider";
import App from "./App";
// import * as serviceWorker from "./serviceWorker";
import configureStore, { history } from "./store";
import { createTheme, ThemeProvider } from "@material-ui/core/styles";
const { store, persistor } = configureStore();

const Sentry = configureSentry();
const theme = createTheme({
  direction: "rtl", // Both here and <body dir="rtl">
});
ReactDOM.render(
  <Provider store={store}>
    <PersistGate loading={null} persistor={persistor}>
      <ConnectedRouter history={history}>
        <ThemeP>
          <ThemeProvider theme={theme}>
            <div dir="rtl">
              <App />
            </div>
          </ThemeProvider>
        </ThemeP>
      </ConnectedRouter>
    </PersistGate>
  </Provider>,
  document.getElementById("root")
);
// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
// serviceWorker.unregister();
