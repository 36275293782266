import { useState } from "react";
import { useDispatch } from "react-redux";
import { push } from "connected-react-router";

// helpers
import reportError from "lib/errorHandler";

// api
import { Api } from "api";

// actions
import { uploadFiles } from "actions";
import { signupSuccess } from "../actions";

// endpoints
import { signupUrl } from "../api/endpoints";

export function useSignUp() {
  const [fetching, setFetching] = useState(false);
  const [error, setError] = useState(null);
  const dispatch = useDispatch();

  async function signUp(
    payload,
    fileUploadLogo,
    fileUploadPieceDidentite,
    fileUploadKbis
  ) {
    try {
      setFetching(true);
      let logoData;
      if (fileUploadLogo) {
        logoData = await uploadFiles(fileUploadLogo);
      }
      let pieceDidentiteData;
      if (fileUploadPieceDidentite) {
        pieceDidentiteData = await uploadFiles(fileUploadPieceDidentite);
      }
      let kbisData;
      if (fileUploadKbis) {
        kbisData = await uploadFiles(fileUploadKbis);
      }
      await Api().post(signupUrl(), {
        ...payload,
        ...(logoData && { logo: logoData.file }),
        ...(pieceDidentiteData && { identity_file: pieceDidentiteData.file }),
        ...(kbisData && { kbis: kbisData.file }),
      });
      dispatch(signupSuccess(payload.email));
      dispatch(push("/auth/success"));
    } catch (error) {
      reportError("signup error", error);
      setError(error);
    } finally {
      setFetching(false);
    }
  }

  return [{ fetching, error }, signUp];
}
