export function meUrl() {
  return "/admins/me";
}

export function logoutUrl() {
  return "/auth/admins/signout";
}

export function uploadUrl() {
  return "/files";
}
