import React, { useState } from "react";
import PageDescription from "components/PageDescription";
import { Row, Col, Label, Button, Spinner } from "reactstrap";
import UploadButton from "components/UploadButton";
import AppInput from "components/AppInput";
import DateTimeInput from "components/DateTimeInput";
import nextId from "react-id-generator";
import CustomSimpleSelect from "components/CustomSimpleSelect";
import { useSelector } from "react-redux";
import { detailsStateSelect } from "../../../selector";
import { LIST_OPTION } from "config/app";
import { useUpdateConseillers } from "../../../hooks/useUpdateConseillers";
const Step8 = () => {
  const { id } = useSelector(detailsStateSelect);
  const { info_regime_retraite } = useSelector(detailsStateSelect);
  const [state, setState] = useState({
    caisse_affiliation: info_regime_retraite?.caisse_affiliation || "",
    num_affiliation: info_regime_retraite?.num_affiliation || "",
    date_affiliation: info_regime_retraite?.date_affiliation || "",
    regime_retraite_complementaire:
      info_regime_retraite?.regime_retraite_complementaire || "",
  });

  const [{ fetching, error }, updateConseillers] = useUpdateConseillers(id);
  const onUpdateData = async () => {
    const payload = {
      info_regime_retraite: {
        caisse_affiliation: state?.caisse_affiliation,
        num_affiliation: state?.num_affiliation,
        date_affiliation: state?.date_affiliation,
        regime_retraite_complementaire: state?.regime_retraite_complementaire,
      },
    };
    updateConseillers(payload);
  };

  const [emptyFields, setEmptyFields] = useState(false);

  const invalidForm =
    !state.caisse_affiliation ||
    !state.num_affiliation ||
    !state.regime_retraite_complementaire;
  return (
    <div>
      <PageDescription title="   نظام التقاعد:" />

      <Row>
        <Col xs="12" sm="4">
          <AppInput
            required
            label="الصندوق المنخرط فيه"
            type="text"
            hasError={emptyFields && !state.caisse_affiliation}
            value={state.caisse_affiliation}
            onChange={(t) => setState({ ...state, caisse_affiliation: t })}
          />
        </Col>
        <Col xs="12" sm="4">
          <AppInput
            required
            label="رقم الانخراط"
            type="text"
            hasError={emptyFields && !state.num_affiliation}
            value={state.num_affiliation}
            onChange={(t) => setState({ ...state, num_affiliation: t })}
          />
        </Col>
      </Row>
      <Row>
        <Col xs="12" sm="4">
          <DateTimeInput
            label="تاريخ الانخراط"
            hasError={emptyFields && !state.date_affiliation}
            minDate={true}
            value={state.date_affiliation}
            onChange={(e) => {
              setState({
                ...state,
                date_affiliation: e,
              });
            }}
          />
        </Col>
        <Col xs="12" sm="4">
          <AppInput
            required
            label="نظام التقاعد التكميلي"
            type="text"
            hasError={emptyFields && !state.regime_retraite_complementaire}
            value={state.regime_retraite_complementaire}
            onChange={(t) =>
              setState({ ...state, regime_retraite_complementaire: t })
            }
          />
        </Col>
      </Row>
      <Row>
        <Col xs="12" sm="8" md="8" lg="4">
          {error && <h6 className="auth-error">{error?.result?.message}</h6>}
          <div className="creation-footer">
            <Button
              className={` space-left themeColor`}
              onClick={(e) => {
                e.preventDefault();
                onUpdateData();
              }}
              size="lg"
              disabled={invalidForm}
            >
              {fetching ? (
                <Spinner size="sm" />
              ) : (
                <span className="text-bold"> تعديل الوثيقة </span>
              )}
            </Button>
          </div>
        </Col>
      </Row>
    </div>
  );
};

export default Step8;
