import React, { useState, useMemo, useEffect } from "react";

import PageDescription from "components/PageDescription";
import AppInput from "components/AppInput";
import CustomSimpleSelect from "components/CustomSimpleSelect";
import CustomAsyncSelect from "components/CustomAsyncSelect";
import { useUpdateDocument } from "modules/Document/hooks/useUpdateDocument";
import useFetchElement from "hooks/useFetchElement";
import { documentByIdUrl } from "../../api/endpoints";
import { Row, Col, Label, Button, Spinner, FormGroup } from "reactstrap";
import Switch from "../../../../components/Switch";
import { useUploadFiles } from "../../hooks/useUploadFiles";
import { LocalConvenienceStoreOutlined } from "@material-ui/icons";
import ModalDelete from "./ModalDelete";
import apiConfig from "config/apiConfig";
const { BASE_URL } = apiConfig;
const Details = (props) => {
  const current = props.details;
  const { data /*, fetching */ } = useFetchElement(
    current,
    documentByIdUrl(props.userRole, current?._id)
  );
  const [
    { fetchingFile, errorFile, idsFile, idsIcon },
    uploadFiles,
  ] = useUploadFiles();

  const Document = useMemo(() => data || current, [current, data]);
  const [state, setState] = useState({
    name: Document.name || "",
    fileName: Document.file_url || "",
    iconName: Document.icon_url || "",
  });
  const [{ fetching, error }, updateDocument] = useUpdateDocument(current?._id);

  const onUpdateData = () => {
    const payload = {
      name: state.name,
      icon: idsIcon?.file,
      file: idsFile?.file,
    };
    updateDocument(payload);
  };

  const HandleUploadFile = async (file) => {
    let fileUpload;
    setState({
      ...state,
      fileName: file.target?.value,
    });
    const fileEvent = file.target.files[0];
    if (fileEvent) {
      fileUpload = new FormData();
      fileUpload.append("file", fileEvent);
      console.log(fileUpload);
      uploadFiles(fileUpload, "file");
    }
  };
  const HandleUploadIcon = async (file) => {
    setState({
      ...state,
      iconName: file.target?.value,
    });
    let fileUpload;
    const fileEvent = file.target.files[0];
    if (fileEvent) {
      fileUpload = new FormData();
      fileUpload.append("file", fileEvent);
      console.log(fileUpload);
      uploadFiles(fileUpload, "icon");
    }
  };
  const [show, setShow] = useState(false);
  return (
    <div className="app-main">
      <ModalDelete
        isOpen={show}
        onCloseModal={() => setShow(false)}
        id={current?._id}
      />
      <PageDescription title="تعديل الوثيقة" />
      <div className="creation-form">
        <Row>
          <Col xs="12" sm="8" md="8" lg="4">
            <AppInput
              label="إسم الوثيقة"
              required
              value={state.name}
              onChange={(t) => setState({ ...state, name: t })}
            />
          </Col>
        </Row>

        <Row>
          <Col xs="12" sm="8" md="8" lg="4" className="InputUpload">
            <label
              style={{ cursor: "pointer", width: "100%" }}
              className="mt-2"
              name="file"
              for="file"
            >
              <div>
                <span className="lableInput">إختيار الوثيقة (pdf)*</span>
                <div className="custom-input">
                  {state.fileName.slice(0, 1) === "C" ? (
                    <span> ...{state.fileName.slice(0, 30)} </span>
                  ) : (
                    <span> ...{state.fileName.slice(8, 30)} </span>
                  )}
                  <div className="input-addon">
                    <img alt="+" src={require("assets/img/iconPdf.png")} />
                  </div>
                </div>
              </div>
              <input
                style={{ display: "none" }}
                id="file"
                type="file"
                name="file"
                accept=".pdf"
                onChange={(file) => {
                  HandleUploadFile(file);
                  // handleChangeTypeFile(item.id, file);
                }}
              />
            </label>
          </Col>
        </Row>
        <Row>
          <Col xs="12" sm="8" md="8" lg="4" className="InputUpload">
            <label
              style={{ cursor: "pointer", width: "100%" }}
              className="mt-2"
              name="icon"
              for="icon"
            >
              <div>
                <span className="lableInput">إختيار الأيقونة (png)*</span>
                <div className="custom-input">
                  {state.iconName.slice(0, 1) === "C" ? (
                    <span> ...{state.iconName.slice(0, 30)} </span>
                  ) : (
                    <span> ...{state.iconName.slice(8, 30)} </span>
                  )}
                  <div className="input-addon">
                    <img alt="+" src={require("assets/img/iconPdf.png")} />
                  </div>
                </div>
              </div>
              <input
                style={{ display: "none" }}
                id="icon"
                type="file"
                name="icon"
                accept=".png"
                onChange={(file) => {
                  HandleUploadIcon(file);
                  // handleChangeTypeFile(item.id, file);
                }}
              />
            </label>
          </Col>
        </Row>

        <Row>
          <Col xs="12" sm="8" md="8" lg="4">
            {error && <h6 className="auth-error">{error?.result?.message}</h6>}
            <div className="creation-footer">
              <Button
                className={` space-left ${props.theme}`}
                onClick={(e) => {
                  e.preventDefault();
                  onUpdateData();
                }}
                size="lg"
                // disabled={invalidForm}
              >
                {fetching ? (
                  <Spinner size="sm" />
                ) : (
                  <span className="text-bold"> حفظ الوثيقة </span>
                )}
              </Button>
              <div
                className="text-bold"
                style={{ color: "red", cursor: "pointer", marginTop: "22px" }}
                onClick={() => setShow(true)}
              >
                حذف الوثيقة
              </div>
            </div>
          </Col>
        </Row>
      </div>
    </div>
  );
};

export default Details;
