import React, { useContext } from "react";
import { useSelector } from "react-redux";
import {
  Navbar,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown,
} from "reactstrap";
import classNames from "classnames";
import { Link } from "react-router-dom";
import apiConfig from "config/apiConfig";
import { ThemeContext } from "contextProviders/ThemeProvider";

import { currentUserSelect } from "../selector";

import "assets/css/breadcrumbs.css";
import "assets/css/topbar.css";

const { BASE_URL } = apiConfig;

const TopBar = (props) => {
  const { toggle, sidebarIsOpen, breadCrumbs, displayLogout } = props;
  const { avatar_url } = useSelector(currentUserSelect) || {};
  const theme = useContext(ThemeContext);
  return (
    <Navbar
      className={classNames("navbar fixed-top", {
        "is-sidebar-open": sidebarIsOpen,
      })}
      expand="lg"
    >
      <div className="navbar-translate display-row">
        <div className="breadcrumbs-container">
          <button
            onClick={() => {
              toggle(!sidebarIsOpen);
            }}
            aria-expanded={sidebarIsOpen}
            className="navbar-toggler"
            style={{ display: "block" }}
          >
            <span
              className="navbar-toggler-bar top-bar"
              style={{
                marginBottom: "8px",
                display: "block",
                position: "relative",
                width: "22px",
                height: "1px",
                borderRadius: "1px",
                background: "rgba(30, 30, 30, 0.97)",
              }}
            ></span>
            <span
              className="navbar-toggler-bar middle-bar"
              style={{
                marginBottom: "8px",
                display: "block",
                position: "relative",
                width: "22px",
                height: "1px",
                borderRadius: "1px",
                background: "rgba(30, 30, 30, 0.97)",
              }}
            ></span>
            <span
              className="navbar-toggler-bar bottom-bar"
              style={{
                marginBottom: "8px",
                display: "block",
                position: "relative",
                width: "22px",
                height: "1px",
                borderRadius: "1px",
                background: "rgba(30, 30, 30, 0.97)",
              }}
            ></span>
          </button>
          {breadCrumbs && breadCrumbs.display && (
            <breadCrumbs.component theme={theme} />
          )}
        </div>
        {/* <div className="topbar-right">
          <UncontrolledDropdown className="button-dropdown button-shaddow">
      
            <DropdownToggle
              caret
              data-toggle="dropdown"
              href="#pablo"
              id="navbarDropdown"
              tag="a"
              onClick={(e) => e.preventDefault()}
            >
              <img
                src={
                  avatar_url
                    ? `${BASE_URL}${avatar_url}`
                    : require("assets/img/avatar.png")
                }
                alt=".."
                className="user-avatar"
              />
            </DropdownToggle>
            <DropdownMenu aria-labelledby="navbarDropdown">
              <DropdownItem to="/passwordreset" tag={Link}>
                <span className="home-navlink text-semi">
                  Changer mot de passe
                </span>
              </DropdownItem>
              <DropdownItem
                onClick={(e) => {
                  displayLogout();
                }}
                tag={Link}
              >
                <span className="home-navlink text-semi">Se déconnecter</span>
              </DropdownItem>
            </DropdownMenu>
          </UncontrolledDropdown>
        </div> */}
      </div>
    </Navbar>
  );
};

export default TopBar;
