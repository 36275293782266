import { createSelector } from "reselect";

export const currentUserStateSelect = createSelector(
  (state) => state.app,
  (app) => app.currentUser
);

export const listStateSelect = createSelector(
  (state) => state.Conseillers,
  (Conseillers) => Conseillers?.list
);

export const detailsStateSelect = createSelector(
  (state) => state.Conseillers,
  (Conseillers) => Conseillers.details
);

export const createStateSelect = createSelector(
  (state) => state.Conseillers,
  (Conseillers) => Conseillers?.create
);
