import React, { useState, useEffect } from "react";
import "../../Document.styles.scss";
import { useSelector } from "react-redux";
import apiConfig from "config/apiConfig";
import { currentUserStateSelect } from "../../selector";
import { Button } from "reactstrap";
import moment from "moment";
import AppInputGroup from "components/AppInputGroup";
import { useUpdateDocument } from "modules/Document/hooks/useUpdateDocument";
const { BASE_URL } = apiConfig;
// const { roles } = useSelector(currentUserStateSelect) || {};

function TableConfig(props) {
  const [state, setState] = useState({ openDropdownT: false });
  const [typeRemiseG, setTypeRemiseG] = useState(props.value);
  const [listDropdown, setListDropdown] = useState([]);
  const [{ fetching, error }, updateDocument] = useUpdateDocument(props._id);
  const onUpdateData = (item) => {
    const payload = {
      pos: item,
    };
    updateDocument(payload);
  };

  useEffect(() => {
    if (fetching) {
      window.location.reload();
    }
  }, [fetching]);

  useEffect(() => {
    const list = () => {
      for (let i = 1; i <= props.count; i++) {
        listDropdown.push(i);
      }
    };

    list();
  }, [props.count]);

  return (
    <div>
      <AppInputGroup
        openDropdown={state.openDropdownT}
        toggleDropdown={() =>
          setState({
            ...state,
            openDropdownT: !state.openDropdownT,
          })
        }
        valueDropdown={typeRemiseG}
        name="remise"
        onChangeDropdown={(item) => {
          onUpdateData(item);
          setTypeRemiseG(item);
        }}
        listDropdown={listDropdown}
      />
    </div>
  );
}

export const columns = [
  {
    title: "",
    dataIndex: ["pos", "_id"],
    renderItem: (info, props, cb, count) => (
      <div className="cell-container text-center">
        <TableConfig count={count} value={info.pos} _id={info._id} />
      </div>
    ),
  },
  {
    title: "الأيقونة",
    dataIndex: "icon_url",
    renderItem: (info, props, cb) => (
      <div className="cell-container text-center" onClick={cb}>
        <img
          src={`${BASE_URL}${info.icon_url}`}
          className="societe-logo"
          alt="."
        />
      </div>
    ),
  },
  {
    title: "إسم الوثيقة",
    dataIndex: "name",
    renderItem: (info, props, cb) => (
      <span style={{ cursor: "pointer" }} onClick={cb} className="text-center">
        {info.name}
      </span>
    ),
  },
  {
    title: "",
    dataIndex: "file_url",
    renderItem: (info, props) => (
      <Button
        style={{ width: "200px" }}
        className={`btn-round text-bold themeColor`}
        color="secondary"
        // onClick={(e) => {
        //   e.preventDefault();
        //   dispatch(push("/conseillers/list"));
        // }}
        size="md"
      >
        <a
          style={{ color: "white" }}
          target="_blank"
          href={`${BASE_URL}${info.file_url}`}
        >
          تحميل الوثيقة
        </a>
      </Button>
    ),
  },

  {
    title: "",
    dataIndex: "none",
    renderItem: (info, props, cb) => {
      return (
        <div style={{ cursor: "pointer" }} onClick={cb} className="text-left">
          <img
            style={{ transform: "rotate(180deg)" }}
            src={require("assets/img/chevron-right.png")}
            className="chevron-right"
            alt=">"
          />
        </div>
      );
    },
  },
];
