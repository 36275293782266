import React from "react";

import { FormGroup, Label } from "reactstrap";

import Select from "react-select";

const customStyles = {
  container: (provided) => ({
    ...provided,
    // backgroundColor: "#f8f8f8",
    borderRadius: "5px",
    height: "90px",
    width: "100%",
  }),
  control: (provided) => ({
    ...provided,
    backgroundColor: "#f8f8f8",
    borderRadius: "5px",
    borderWidth: "0px",
    height: "50px",
    width: "100%",
    color: "#373737",
    fontSize: "1.05em",
    fontWeight: "bold",
  }),
};

const SelectInput = ({
  options,
  label,
  onSelectValue,
  required,
  name,
  id,
  defaultValue,
  isClearable,
  value,
  ...props
}) => {
  return (
    <FormGroup>
      <Label className="text-black lableInput" for={name}>
        {label}
        {required && <span>*</span>}
      </Label>
      <Select
        styles={customStyles}
        placeholder=""
        isClearable={isClearable}
        // onInputChange={(e) => {
        //   console.log("input change", e);
        // }}
        onChange={(select) => onSelectValue(select)}
        id={id}
        value={value}
        defaultValue={props.defaultValue}
        options={options}
      />
    </FormGroup>
  );
};

export default SelectInput;
