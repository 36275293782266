import React, { useState } from "react";
import {
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap";
import { THEME_OPTIONS } from "config/app";

const ColorPicker = (props) => {
  const [dropdownOpen, setDropdownOpen] = useState(false);

  const toggle = () => {
    setDropdownOpen((prevState) => !prevState);
  };

  return (
    <Dropdown isOpen={dropdownOpen} toggle={toggle}>
      <DropdownToggle
        aria-expanded={false}
        aria-haspopup={true}
        data-toggle="dropdown"
        data-stripe={props.dataStripe}
        href="#pablo"
        id="dropdownMenuLink"
        onClick={(e) => e.preventDefault()}
        className="btn btn-input"
      >
        {props.selected ? (
          <span
            style={{
              backgroundColor: props.selected.label,
              height: 20,
              width: 60,
              borderRadius: 2,
            }}
          ></span>
        ) : (
          <span />
        )}
        <img
          src={require("assets/img/dropdown.png")}
          className="dropdown-arrow"
          alt=">"
        />
      </DropdownToggle>
      <DropdownMenu className="dropdown-options">
        {/* {THEME_OPTIONS.map((option) => (
          <DropdownItem
            key={option.value}
            onClick={(e) => props.setSelected(option)}
          >
            <span>
              {`${option.text}`}
              <span className={`color-${option.value}`}></span>
            </span>
          </DropdownItem>
        ))} */}
      </DropdownMenu>
    </Dropdown>
  );
};

export default ColorPicker;
