import Switch from "@material-ui/core/Switch";
import { withStyles } from "@material-ui/core/styles";

const PrimarySwitch = withStyles({
  switchBase: {
    color: "#037cc4",
    "&$checked": {
      color: "#009ee4",
    },
    "&$checked + $track": {
      backgroundColor: "#009ee4",
    },
  },
  checked: {},
  track: {},
})(Switch);

export default PrimarySwitch;
