import { useState } from "react";
import { useDispatch } from "react-redux";
import { push } from "connected-react-router";

// helpers
import reportError from "lib/errorHandler";

// api
import { Api } from "api";

// actions
import { uploadFiles } from "actions";

// endpoints
import { createDocumentUrl } from "../api/endpoints";

export function useCreateDocument() {
  const [fetching, setFetching] = useState(false);
  const [error, setError] = useState(null);
  const dispatch = useDispatch();

  async function createDocument(payload, uploadPayload, role) {
    console.log("payload", payload);
    try {
      setFetching(true);
      let logoData;
      if (uploadPayload) {
        logoData = await uploadFiles(uploadPayload);
      }
      await Api().post(createDocumentUrl(role), {
        ...payload,
      });
      dispatch(push("/document/list"));
    } catch (error) {
      reportError("createProduct error", error);
      setError(error);
      setFetching(false);
    } finally {
      // setFetching(false);
    }
  }

  return [{ fetching, error }, createDocument];
}
