import Settings from "modules/Settings";
import Document from "modules/Document";
import PasswordReset from "modules/PasswordReset";
import Conseillers from "modules/Conseillers";
const routes = [
  { path: "/settings", component: Settings },
  { path: "/document", component: Document },
  { path: "/conseillers", component: Conseillers },
  { path: "/passwordreset", component: PasswordReset },
];

export default routes;
