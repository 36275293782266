import React, { useState } from "react";
import PageDescription from "components/PageDescription";
import { Row, Col, Label, Button, Spinner } from "reactstrap";
import UploadButton from "components/UploadButton";
import AppInput from "components/AppInput";
import DateTimeInput from "components/DateTimeInput";
import nextId from "react-id-generator";
import CustomSimpleSelect from "components/CustomSimpleSelect";
import { useSelector } from "react-redux";
import { detailsStateSelect } from "../../../selector";
import { LIST_OPTION } from "config/app";
import { useUpdateConseillers } from "../../../hooks/useUpdateConseillers";
const Step7 = () => {
  const { info_situation_statutaire } = useSelector(detailsStateSelect);
  const { id } = useSelector(detailsStateSelect);
  const [state, setState] = useState({
    administration_origine:
      info_situation_statutaire?.administration_origine || "",
    som: info_situation_statutaire?.som || "",
    cadre: info_situation_statutaire?.cadre || "",
    echelle: info_situation_statutaire?.echelle || "",
    echelon: info_situation_statutaire?.echelon || "",
    indice: info_situation_statutaire?.indice || "",
    num_immatriculation_cnss:
      info_situation_statutaire?.num_immatriculation_cnss || "",
    num_affiliationn_cnss:
      info_situation_statutaire?.num_affiliationn_cnss || "",
    num_hierarchique: info_situation_statutaire?.num_hierarchique || "",
  });

  const [emptyFields, setEmptyFields] = useState(false);

  const [{ fetching, error }, updateConseillers] = useUpdateConseillers(id);
  const onUpdateData = async () => {
    const payload = {
      info_situation_statutaire: {
        administration_origine: state?.administration_origine,
        som: state?.som,
        cadre: state?.cadre,
        echelle: state?.echelle,
        echelon: state?.echelon,
        indice: state?.indice,
        num_immatriculation_cnss: state?.num_immatriculation_cnss,
        num_affiliationn_cnss: state?.num_affiliationn_cnss,
        num_hierarchique: state?.num_hierarchique,
      },
    };
    updateConseillers(payload);
  };

  return (
    <div>
      <PageDescription title="   الوضعية النظامية :" />
      <Row>
        <div
          style={{ textAlign: "right", fontWeight: "700", marginRight: "16px" }}
        >
          القطاع العام او شبه العام
        </div>
      </Row>
      <Row>
        <Col xs="12" sm="4">
          <AppInput
            label=" الإدارة الأصلية"
            type="text"
            hasError={emptyFields && !state.administration_origine}
            value={state.administration_origine}
            onChange={(t) => setState({ ...state, administration_origine: t })}
          />
        </Col>
        <Col xs="12" sm="4">
          <AppInput
            label=" رقم التأجي"
            type="text"
            hasError={emptyFields && !state.som}
            value={state.som}
            onChange={(t) => setState({ ...state, som: t })}
          />
        </Col>
        <Col xs="12" sm="4">
          <AppInput
            label="الإطار"
            type="text"
            hasError={emptyFields && !state.cadre}
            value={state.cadre}
            onChange={(t) => setState({ ...state, cadre: t })}
          />
        </Col>
      </Row>
      <Row>
        <Col xs="12" sm="4">
          <AppInput
            label="السلم"
            type="text"
            hasError={emptyFields && !state.echelle}
            value={state.echelle}
            onChange={(t) => setState({ ...state, echelle: t })}
          />
        </Col>
        <Col xs="12" sm="4">
          <AppInput
            label="الدرجة"
            type="text"
            hasError={emptyFields && !state.echelon}
            value={state.echelon}
            onChange={(t) => setState({ ...state, echelon: t })}
          />
        </Col>
        <Col xs="12" sm="4">
          <AppInput
            label="الرقم الاستدلالي"
            type="text"
            hasError={emptyFields && !state.indice}
            value={state.indice}
            onChange={(t) => setState({ ...state, indice: t })}
          />
        </Col>
      </Row>

      <Row>
        <div
          style={{ textAlign: "right", fontWeight: "700", marginRight: "16px" }}
        >
          القطاع الخاص
        </div>
      </Row>
      <Row>
        <Col xs="12" sm="4">
          <AppInput
            label=" رقم التسجيل في الصندوق الوطني للضمان الاجتماعي"
            type="text"
            hasError={emptyFields && !state.num_immatriculation_cnss}
            value={state.num_immatriculation_cnss}
            onChange={(t) =>
              setState({ ...state, num_immatriculation_cnss: t })
            }
          />
        </Col>
        <Col xs="12" sm="4">
          <AppInput
            label="  رقم انخراط مشغلكم في الصندوق الوطني للضمان الاجتماعي"
            type="text"
            hasError={emptyFields && !state.num_affiliationn_cnss}
            value={state.num_affiliationn_cnss}
            onChange={(t) => setState({ ...state, num_affiliationn_cnss: t })}
          />
        </Col>
      </Row>

      <Row>
        <div
          style={{ textAlign: "right", fontWeight: "700", marginRight: "16px" }}
        >
          القطاع شبه العمومي
        </div>
      </Row>
      <Row>
        <Col xs="12" sm="4">
          <AppInput
            label="الرقم التسلسلي"
            type="text"
            hasError={emptyFields && !state.num_hierarchique}
            value={state.num_hierarchique}
            onChange={(t) => setState({ ...state, num_hierarchique: t })}
          />
        </Col>
      </Row>
      <Row>
        <div
          style={{ textAlign: "right", fontWeight: "700", marginRight: "16px" }}
        >
          مهني أو عامل مستقل أو مزاول لنشاط خاص
        </div>
      </Row>
      <Row>
        <Col xs="12" sm="4">
          <AppInput
            label="رقم التسجيل في الصندوق الوطني للضمان الاجتماعي"
            type="text"
            hasError={emptyFields && !state.num_immatriculation_cnss}
            value={state.num_immatriculation_cnss}
            onChange={(t) =>
              setState({ ...state, num_immatriculation_cnss: t })
            }
          />
        </Col>
      </Row>
      <Row>
        <Col xs="12" sm="8" md="8" lg="4">
          {error && <h6 className="auth-error">{error?.result?.message}</h6>}
          <div className="creation-footer">
            <Button
              className={` space-left themeColor`}
              onClick={(e) => {
                e.preventDefault();
                onUpdateData();
              }}
              size="lg"
              // disabled={invalidForm}
            >
              {fetching ? (
                <Spinner size="sm" />
              ) : (
                <span className="text-bold"> تعديل الوثيقة </span>
              )}
            </Button>
          </div>
        </Col>
      </Row>
    </div>
  );
};

export default Step7;
