import { useState } from "react";
import { useDispatch } from "react-redux";
import { push } from "connected-react-router";

// helpers
import reportError from "lib/errorHandler";

// api
import { Api } from "api";
import { uploadFileUrl } from "../api/endpoints";
// actions
import { uploadFiles } from "actions";

export function useUploadFiles() {
  const [fetchingFile, setFetchingFile] = useState(false);
  const [idsFile, setIdsFile] = useState();
  const [errorFile, setError] = useState(null);
  const dispatch = useDispatch();

  async function uploadFile(uploadPayload) {
    try {
      setFetchingFile(true);
      let logoData;
      if (uploadPayload) {
        logoData = await new Promise(async (resolve, reject) => {
          try {
            const data = await Api().post(uploadFileUrl(), uploadPayload, true);
            resolve(data);
          } catch (err) {
            reportError("upload files error ", err);
            reject(err);
          }
        });
        setIdsFile(logoData);
        dispatch(push("/conseillers/list"));
        setFetchingFile(false);
      }
    } catch (errorFile) {
      reportError("updatecyCleLong error", errorFile);
      setError(errorFile);
      setFetchingFile(false);
    } finally {
      // setFetching(false);
    }
  }
  return [{ fetchingFile, errorFile, idsFile }, uploadFile];
}
