import React, { useState, useEffect, useMemo } from "react";
import PageDescription from "components/PageDescription";
import { Row, Col, Label, Button, Spinner } from "reactstrap";
import UploadButton from "components/UploadButton";
import AppInput from "components/AppInput";
import DateTimeInput from "components/DateTimeInput";
import nextId from "react-id-generator";
import CustomSimpleSelect from "components/CustomSimpleSelect";
import { useUpdateConseillers } from "../../../hooks/useUpdateConseillers";
import apiConfig from "config/apiConfig";
import { useSelector } from "react-redux";
import { detailsStateSelect } from "../../../selector";
import { LIST_OPTION } from "config/app";
const Step2 = (props) => {
  const { info_electorales } = useSelector(detailsStateSelect);
  const { id } = useSelector(detailsStateSelect);
  const [state, setState] = useState({
    circonscription: info_electorales.circonscription || "",
    groupement: info_electorales.groupement || "",
    appartenance:
      LIST_OPTION.find(
        (item) => item.value === info_electorales?.appartenance
      ) || "",
    parti_politique: info_electorales.parti_politique || "",
    parti: info_electorales.parti || "",
    syndicat: info_electorales.syndicat || "",
    organisation_pro: info_electorales.organisation_pro || "",
    autre_affiliation: info_electorales.autre_affiliation || "",
    college_electoral:
      LIST_OPTION.find(
        (item) => item.value === info_electorales?.college_electoral
      ) || "",
    collectivite_territoriale:
      LIST_OPTION.find(
        (item) => item.value === info_electorales?.collectivite_territoriale
      ) || "",
    precision_fonctions: info_electorales.precision_fonctions || "",
    population_depasse:
      LIST_OPTION.find(
        (item) => item.value === info_electorales?.population_depasse
      ) || "",
    intercommunal:
      LIST_OPTION.find(
        (item) => item.value === info_electorales?.intercommunal
      ) || "",
    fonction_intercommunal: info_electorales.fonction_intercommunal || "",
    membre_conseil:
      LIST_OPTION.find(
        (item) => item.value === info_electorales?.membre_conseil
      ) || "",
    fonctions_conseil: info_electorales.fonctions_conseil || "",
    chambre_pro:
      LIST_OPTION.find(
        (item) => item.value === info_electorales?.chambre_pro
      ) || "",
    function_chambre_pro: info_electorales.function_chambre_pro || "",
    elus_organisme_pro: info_electorales.elus_organisme_pro || "",
    organisme_pro: info_electorales.organisme_pro || "",
    functions_organisme_pro: info_electorales.functions_organisme_pro || "",
    reprrsentants_salarirs: info_electorales.reprrsentants_salarirs || "",
    syndica_echeant: info_electorales.syndica_echeant || "",
    fonctions_syndicat: info_electorales.fonctions_syndicat || "",
    fonctions_electives:
      LIST_OPTION.find(
        (item) => item.value === info_electorales?.fonctions_electives
      ) || "",
  });

  const [emptyFields, setEmptyFields] = useState(false);
  const [{ fetching, error }, updateConseillers] = useUpdateConseillers(id);
  const onUpdateData = async () => {
    const payload = {
      info_electorales: {
        circonscription: state.circonscription,
        groupement: state.groupement,
        appartenance: state?.appartenance?.value,
        parti_politique: state.parti_politique,
        parti: state.parti,
        syndicat: state.syndicat,
        organisation_pro: state.organisation_pro,
        autre_affiliation: state.autre_affiliation,
        college_electoral: state?.college_electoral?.value,
        collectivite_territoriale: state?.collectivite_territoriale?.value,
        precision_fonctions: state.precision_fonctions,
        population_depasse: state?.population_depasse.value,
        intercommunal: state?.intercommunal?.value,

        fonction_intercommunal: state.fonction_intercommunal,
        membre_conseil: state?.membre_conseil?.value,
        fonctions_conseil: state.fonctions_conseil,
        chambre_pro: state?.chambre_pro.value,
        function_chambre_pro: state.function_chambre_pro,
        elus_organisme_pro: state.elus_organisme_pro,
        organisme_pro: state.organisme_pro,
        functions_organisme_pro: state.functions_organisme_pro,
        reprrsentants_salarirs: state.reprrsentants_salarirs,
        syndica_echeant: state.syndica_echeant,
        fonctions_syndicat: state.fonctions_syndicat,
        fonctions_electives: state?.fonctions_electives?.value,
      },
    };
    updateConseillers(payload);
  };

  const invalidForm =
    !state.circonscription ||
    !state.groupement ||
    !state.appartenance ||
    (!state.syndicat && state.appartenance?.value === "SYNDICAT") ||
    !(
      state.organisation_pro && state.appartenance?.value === "ORGANISATION_PRO"
    ) ||
    !state.college_electoral ||
    !state.collectivite_territoriale ||
    !state.population_depasse ||
    !state.intercommunal ||
    !state.membre_conseil ||
    !state.chambre_pro ||
    !state.function_chambre_pro;
  return (
    <div>
      <PageDescription title="معلومات انتخابية :" />
      <br />
      <Row>
        <Col xs="12" sm="3">
          <AppInput
            label="الدائرة الانتخابية"
            required
            hasError={emptyFields && !state.circonscription}
            value={state.circonscription}
            onChange={(t) => setState({ ...state, circonscription: t })}
          />
        </Col>
        <Col xs="12" sm="3">
          <AppInput
            label="الفريق أو المجموعة بمجلس المستشارين"
            required
            hasError={emptyFields && !state.groupement}
            value={state.groupement}
            onChange={(t) => setState({ ...state, groupement: t })}
          />
        </Col>
      </Row>

      <Row>
        <Col xs="12" sm="6">
          <CustomSimpleSelect
            label=" الانتماء السياسي أو النقابي أو المهني الذي على أساسه اكتسبت العضوية بمجلس المستشارين"
            required
            options={[
              { label: "حزب السياسي", value: "PARTI" },
              { label: "هيئة النقابية", value: "SYNDICAT" },
              { label: "المنظمة المهنية للمشغلين", value: "ORGANISATION_PRO" },
              {
                label: " بدون انتماء سياسي أو نقابي أو مهني",
                value: "SANS",
              },
            ]}
            value={state.appartenance}
            onSelectValue={(v) => {
              setState({ ...state, appartenance: v === null ? "" : v });
            }}
          />
        </Col>
        <Col xs="12" sm="3">
          {state.appartenance?.value === "PARTI" && (
            <AppInput
              label="تحديد الحزب السياسي "
              // hasError={emptyFields && !state.parti}
              value={state.parti_politique}
              onChange={(t) => setState({ ...state, parti_politique: t })}
            />
          )}
          {state.appartenance?.value === "SYNDICAT" && (
            <AppInput
              label="تحديد الهيئة النقابية "
              required
              hasError={emptyFields && !state.syndicat}
              value={state.syndicat}
              onChange={(t) => setState({ ...state, syndicat: t })}
            />
          )}
          {state.appartenance?.value === "ORGANISATION_PRO" && (
            <AppInput
              label="تحديد المنظمة المهنية للمشغلين "
              required
              hasError={emptyFields && !state.organisation_pro}
              value={state.organisation_pro}
              onChange={(t) => setState({ ...state, organisation_pro: t })}
            />
          )}
          {state.appartenance?.value === "SANS" && (
            <AppInput
              label="تحديد أي انتساب آخر يمكن أخذه بالاعتبار (إن وجد)"
              hasError={emptyFields && !state.autre_affiliation}
              value={state.autre_affiliation}
              onChange={(t) => setState({ ...state, autre_affiliation: t })}
            />
          )}
        </Col>
      </Row>
      <Row>
        <Col xs="12" sm="6">
          <CustomSimpleSelect
            label="الهيئة المترشح باسمها لانتخاب أعضاء مجلس المستشارين"
            required
            hasError={emptyFields && !state.college_electoral}
            options={[
              {
                label: "جماعة ترابية",
                value: "COLLECTIVITE_TERRITORIALE",
              },
              { label: "هيئة النقابيةغرفة مهنية", value: "CHAMBRE_PRO" },
              { label: "منظمة مهنية للمشغلين", value: "ORGANISATION_PRO" },
              {
                label: "ممثلي المأجورين",
                value: "REPRESENTANT_SALARIES",
              },
            ]}
            value={[
              {
                label: state.college_electoral,
                value: state.college_electoral,
              },
            ]}
            value={state.college_electoral}
            onSelectValue={(v) => {
              setState({ ...state, college_electoral: v === null ? "" : v });
            }}
          />
        </Col>
      </Row>
      <Row>
        <Col xs="12" sm="6">
          <CustomSimpleSelect
            label=" المنتخبون باسم جماعة ترابية تحديد طبيعة الجماعة الترابية  "
            required
            options={[
              { label: "مجلس جهة", value: "REGION" },
              { label: "مجلس عمالة أو إقليم", value: "PREFECTURE_PROVINCE" },
              { label: "مجلس جماعة", value: "COMMUNE" },
            ]}
            value={state.collectivite_territoriale}
            onSelectValue={(v) => {
              setState({
                ...state,
                collectivite_territoriale: v === null ? "" : v,
              });
            }}
          />
        </Col>

        <Col xs="12" sm="3">
          <AppInput
            label="تحديد المهام "
            hasError={emptyFields && !state.precision_fonctions}
            value={state.precision_fonctions}
            onChange={(t) => setState({ ...state, precision_fonctions: t })}
          />
        </Col>
      </Row>
      <Row>
        <Col xs="12" sm="6">
          <CustomSimpleSelect
            label="إذا كنتم رئيسا للمجلس الجماعي. هل يفوق عدد سكان الجماعة 300.000 نسمة؟"
            required
            options={[
              { label: "نعم", value: true },
              { label: "لا", value: false },
            ]}
            value={state.population_depasse}
            onSelectValue={(v) => {
              setState({ ...state, population_depasse: v === null ? "" : v });
            }}
          />
        </Col>
      </Row>
      <Row>
        <Col xs="12" sm="6">
          <CustomSimpleSelect
            label="هل تتولون مهاما بمجموعة مؤسسة من قبل جماعات ترابية (مجموعة جماعات) ؟"
            required
            options={[
              { label: "نعم", value: true },
              { label: "لا", value: false },
            ]}
            value={state.intercommunal}
            onSelectValue={(v) => {
              setState({ ...state, intercommunal: v === null ? "" : v });
            }}
          />
        </Col>
        {state.intercommunal.value && (
          <Col xs="12" sm="3">
            <AppInput
              label="يرجى تحديد المهام (إن وجدت)"
              hasError={emptyFields && !state.fonction_intercommunal}
              value={state.fonction_intercommunal}
              onChange={(t) =>
                setState({ ...state, fonction_intercommunal: t })
              }
            />
          </Col>
        )}
      </Row>
      <Row>
        <Col xs="12" sm="6">
          <CustomSimpleSelect
            label="هل أنتم عضو بمجلس جماعة ترابية أخرى، غير الجماعة الترابية التي ترشحتم عنها؟"
            required
            options={[
              { label: "نعم", value: true },
              { label: "لا", value: false },
            ]}
            value={state.membre_conseil}
            onSelectValue={(v) => {
              setState({ ...state, membre_conseil: v === null ? "" : v });
            }}
          />
        </Col>
        {state.membre_conseil.value && (
          <Col xs="12" sm="3">
            <AppInput
              label="يرجى  تحديد مهامكم بذلك المجلس (إن وجدت)"
              hasError={emptyFields && !state.fonctions_conseil}
              value={state.fonctions_conseil}
              onChange={(t) => setState({ ...state, fonctions_conseil: t })}
            />
          </Col>
        )}
      </Row>
      <Row>
        <Col xs="12" sm="6">
          <CustomSimpleSelect
            label=" المنتخبون باسم غرفة مهنية (تحديد طبيعة الغرفة المهنية )"
            required
            options={[
              { label: "غرفة فلاحية ", value: "AGRICULTURE" },
              { label: "غرف التجارة و الصناعة والخدمات", value: "COMMERCE" },
              { label: "غرف الصيد البحري", value: "MARITIME" },
              { label: "غرف الصناعة التقليدية", value: "ARTISANAT" },
            ]}
            value={state.chambre_pro}
            onSelectValue={(v) => {
              setState({ ...state, chambre_pro: v === null ? "" : v });
            }}
          />
        </Col>
        <Col xs="12" sm="3">
          <AppInput
            required
            label="تحديد المهام"
            hasError={emptyFields && !state.function_chambre_pro}
            value={state.function_chambre_pro}
            onChange={(t) => setState({ ...state, function_chambre_pro: t })}
          />
        </Col>
      </Row>
      <Row>
        <Col xs="12" sm="8">
          <div style={{ textAlign: "right", fontWeight: "700" }}>
            المنتخبون باسم منظمة مهنية للمشغلين (تحديد المنظمة)
          </div>
        </Col>
      </Row>
      <Row>
        <Col xs="12" sm="3">
          <AppInput
            label="المنظمة المهنية للمشغلين"
            hasError={emptyFields && !state.organisme_pro}
            value={state.organisme_pro}
            onChange={(t) => setState({ ...state, organisme_pro: t })}
          />
        </Col>
        <Col xs="12" sm="3">
          <AppInput
            label="المهام"
            hasError={emptyFields && !state.functions_organisme_pro}
            value={state.functions_organisme_pro}
            onChange={(t) => setState({ ...state, functions_organisme_pro: t })}
          />
        </Col>
      </Row>
      <Row>
        <Col xs="12" sm="3">
          <div style={{ textAlign: "right", fontWeight: "700" }} s>
            المنتخبون ممثلي المأجورين{" "}
          </div>
        </Col>
      </Row>
      <Row>
        <Col xs="12" sm="3">
          <AppInput
            label="النقابة (إن وجدت)"
            hasError={emptyFields && !state.syndica_echeant}
            value={state.syndica_echeant}
            onChange={(t) => setState({ ...state, syndica_echeant: t })}
          />
        </Col>
        <Col xs="12" sm="3">
          <AppInput
            label="المهام"
            hasError={emptyFields && !state.fonctions_syndicat}
            value={state.fonctions_syndicat}
            onChange={(t) => setState({ ...state, fonctions_syndicat: t })}
          />
        </Col>
      </Row>
      <Row>
        <Col xs="12" sm="6">
          <CustomSimpleSelect
            label="هل تزاولون أيا من المهام الانتدابية التالية"
            options={[
              { label: " رئيس مجلس جهة", value: "PRESIDENT_CONSEIL_REGION" },
              {
                label: "رئيس مجلس عمالة أو إقليم",
                value: "PRESIDENT_CONSEIL_PREFECTURE",
              },
              {
                label: "  رئيس مجلس جماعة",
                value: "PRESIDENT_CONSEIL_COMMUNE",
              },
              {
                label: "رئيس مجلس مقاطعة جماعية",
                value: "PRESIDENT_CONSEIL_ARRONDISSEMENT",
              },
              {
                label: "   رئيس مجموعة تؤسسها جماعات ترابية",
                value: "PRESIDENT_GROUPEMENT_INTERCOMMUNAL",
              },
              { label: "رئيس غرفة مهنية", value: "PRESIDENT_CHAMBRE_PRO" },
            ]}
            value={state.fonctions_electives}
            onSelectValue={(v) => {
              setState({ ...state, fonctions_electives: v === null ? "" : v });
            }}
          />
        </Col>
      </Row>
      <Row>
        <Col xs="12" sm="8" md="8" lg="4">
          {error && <h6 className="auth-error">{error?.result?.message}</h6>}
          <div className="creation-footer">
            <Button
              className={` space-left themeColor`}
              onClick={(e) => {
                e.preventDefault();
                onUpdateData();
              }}
              size="lg"
              disabled={invalidForm}
            >
              {fetching ? (
                <Spinner size="sm" />
              ) : (
                <span className="text-bold"> تعديل الوثيقة </span>
              )}
            </Button>
          </div>
        </Col>
      </Row>
    </div>
  );
};

export default Step2;
