import React from "react";

import { FormGroup, Label } from "reactstrap";

// import AsyncSelect from "react-select/async";
import Select from "react-select";
import { Api } from "api";

const customStyles = {
  container: (provided) => ({
    ...provided,
    // backgroundColor: "#f8f8f8",
    borderRadius: "5px",
    height: "120px",
    width: "100%",
  }),
  control: (provided) => ({
    ...provided,
    backgroundColor: "#f8f8f8",
    borderRadius: "5px",
    borderWidth: "0px",
    height: "50px",
    width: "100%",
    color: "#373737",
    fontSize: "1.05em",
    fontWeight: "bold",
  }),
};

const SelectInput = ({
  url,
  label,
  onSelectValue,
  required,
  options,
  value,
  name,
  ...props
}) => {
  const loadOptions = (inputValue) =>
    new Promise(async (resolve) => {
      try {
        const filter = inputValue ? `$q=${inputValue}` : "";
        const data = await Api().get(`${url}/?${filter}`);
        // setEmployers(data.value);
        const formatted = data.value.map((one) => ({
          value: one._id,
          label: one.name.first
            ? `${one.name.first} ${one.name.last}`
            : one.name,
        }));
        resolve(formatted);
      } catch {
        resolve([]);
      }
    });

  return (
    <FormGroup>
      <Label className="lableInput text-black" for={name}>
        {label}
        {required && <span>*</span>}
      </Label>
      <Select
        styles={customStyles}
        placeholder=""
        cacheOptions
        isMulti
        // loadOptions={loadOptions}
        options={options}
        defaultOptions
        onInputChange={(e) => {
          console.log("input change", e);
        }}
        onChange={(select) => onSelectValue(select)}
        id={props.id}
        inputId={props.id}
        value={value}
        // defaultValue={props.defaultValue}
      />
    </FormGroup>
  );
};

export default SelectInput;
