export const GOOGLE_API_KEY = "AIzaSyBXWOxwBiZNJf_cTQ3NwieZfZhJrH4lPJg";

export const LIST_OPTION = [
  { label: "أعزب", value: "CELIBATAIRE" },
  { label: "متزوج", value: "MARIE" },
  { label: "مطلق(ة)", value: "DIVORCE" },
  { label: "أرمل (ة)", value: "VEUF" },
  { label: "حزب السياسي", value: "PARTI" },
  { label: "هيئة النقابية", value: "SYNDICAT" },
  { label: "المنظمة المهنية للمشغلين", value: "ORGANISATION_PRO" },
  {
    label: " بدون انتماء سياسي أو نقابي أو مهني",
    value: "SANS",
  },
  {
    label: "  جماعة ترابية ",
    value: "COLLECTIVITE_TERRITORIALE",
  },
  { label: "هيئة النقابيةغرفة مهنية", value: "CHAMBRE_PRO" },
  { label: "منظمة مهنية للمشغلين", value: "ORGANISATION_PRO" },
  {
    label: "ممثلي المأجورين",
    value: "REPRESENTANT_SALARIES",
  },
  { label: "مجلس جهة", value: "REGION" },
  { label: "مجلس عمالة أو إقليم", value: "PREFECTURE_PROVINCE" },
  { label: "مجلس جماعة", value: "COMMUNE" },
  { label: "نعم", value: true },
  { label: "لا", value: false },
  { label: "غرفة فلاحية ", value: "AGRICULTURE" },
  { label: "غرف التجارة و الصناعة والخدمات", value: "COMMERCE" },
  { label: "غرف الصيد البحري", value: "MARITIME" },
  { label: "غرف الصناعة التقليدية", value: "ARTISANAT" },
  { label: " رئيس مجلس جهة", value: "PRESIDENT_CONSEIL_REGION" },
  {
    label: "رئيس مجلس عمالة أو إقليم",
    value: "PRESIDENT_CONSEIL_PREFECTURE",
  },
  {
    label: "  رئيس مجلس جماعة",
    value: "PRESIDENT_CONSEIL_COMMUNE",
  },
  {
    label: "رئيس مجلس مقاطعة جماعية",
    value: "PRESIDENT_CONSEIL_ARRONDISSEMENT",
  },
  {
    label: "   رئيس مجموعة تؤسسها جماعات ترابية",
    value: "PRESIDENT_GROUPEMENT_INTERCOMMUNAL",
  },
  { label: "رئيس غرفة مهنية", value: "PRESIDENT_CHAMBRE_PRO" },
  { label: "ابتدائي", value: "PRIMAIRE" },
  { label: " ثانوي إعدادي", value: "COLLEGE" },
  { label: " ثانوي تأهيلي", value: "LYCEE" },
  { label: "جامعي", value: "UNIVERSITE" },
  {
    label: "شهادة الدروس الجامعية العامة أو ما يعادلها أو باك+2",
    value: "BAC_2",
  },
  {
    label: "(الإجازة أو ما يعادلها أو باك+3 (أو باك+4",
    value: "BAC_3",
  },
  {
    label:
      "(الماستر أو شهادة الدراسات العليا المعمقة أو دبلوم مهندس دولة أو ما يعادلها أو باك+5 (أو باك+6",
    value: "BAC_5",
  },
  {
    label: "الدكتوراه",
    value: "DOCTORAT",
  },
  { label: "أخرى", value: "AUTRE" },
  { label: "نشاط أو مهنة حرة", value: "LIBERALE" },
  { label: " القطاع العام", value: "PUBLIC" },
  { label: "  القطاع الخاص", value: "PRIVE" },
  { label: " قطاع الاقتصاد المختلط", value: "MIXTE" },
  { label: " رئيس مجلس الادارة  ", value: "PRESIDENT" },
  { label: "  مدير عام أو مدير عام منتدب", value: "DIRECTEUR" },
  { label: "متصرف", value: "ADMINISTRATEUR" },
  {
    label: "عضو مجلس الإدارة الجماعية",
    value: "MEMBRE_DIRECTOIRE",
  },
  { label: "عضو مجلس الرقابة", value: "MEMBRE_CONSEIL" },
  { label: "نعم", value: "OUI" },
  { label: "لا", value: "NON" },
  { label: "دائمة", value: "PERMANENTE" },
  { label: "مؤقتة", value: "PROVISOIRE" },
  { label: "عضوية المكتب", value: "CELIBATAIRE" },
  { label: "رئاسة لجنة", value: "MARIE" },
  { label: "عضو فريق أو مجموعة", value: "DIVORCE" },
  { label: "عضوية المكتب", value: "CELIBATAIRE" },
  { label: "رئاسة لجنة", value: "MARIE" },
  { label: "عضو فريق أو مجموعة", value: "DIVORCE" },
  { label: "عضوية المكتب", value: "CELIBATAIRE" },
  { label: "رئاسة لجنة", value: "MARIE" },
  { label: "عضو فريق أو مجموعة", value: "DIVORCE" },
  { label: "عضوية المكتب", value: "CELIBATAIRE" },
  { label: "رئاسة لجنة", value: "MARIE" },
  { label: "عضو فريق أو مجموعة", value: "DIVORCE" },
  { label: "عضوية المكتب", value: "CELIBATAIRE" },
];
