// import front-end config
import { SET_CURRENT_DOCUMENT } from "../actionsTypes";
import { LOGOUT_S } from "actionsTypes";

// import {REHYDRATE} from 'redux-persist/lib/constants';

/**
 * ## InitialState
 *
 * The fields we're concerned with
 */
const initialState = {
  current: null,
};

/**
 * ## Reducer function
 * @param {Object} state - initialState
 * @param {Object} action - type and payload
 */
export default function create(state = initialState, action) {
  switch (action.type) {
    case SET_CURRENT_DOCUMENT:
      return {
        ...state,
        current: action.payload,
      };

    case LOGOUT_S:
      return initialState;

    default:
      return state;
  }
}
