import React, { useContext } from "react";
import { Row, Col } from "reactstrap";
import { ThemeContext } from "contextProviders/ThemeProvider";
const PageDescription = (props) => {
  const theme = useContext(ThemeContext);
  return (
    <div className="page-description ">
      <Row>
        <Col md="7" className="page-description-content">
          <div>
            <h3
              style={{ width: "max-content" }}
              className={`page-title-text app-text-${theme}`}
            >
              {props.title}
            </h3>
          </div>
        </Col>
      </Row>
    </div>
  );
};

export default PageDescription;
