import React, { useEffect, useState } from "react";

// reactstrap components
import {
  Button,
  Card,
  CardBody,
  Row,
  Col,
  Form,
  Label,
  Spinner,
} from "reactstrap";
// import Inputmask from 'inputmask';

import ErrorLabel, { usePasswordCheck } from "hooks/usePasswordCheck";
import { geocodeAddress } from "lib/helpers";
// import {PAYS} from 'config/app';

// some hooks
import { useSignUp } from "../../hooks/useSignUp";

// core components
import AuthNavbar from "components/Navbars/AuthNavbar";
import AppInput from "components/AppInput";
import CustomSimpleSelect from "components/CustomSimpleSelect";
import UploadButton from "components/UploadButton";
import GooglePlaceInput from "components/GooglePlaceInput";

import "./signup-styles.scss";

function SignupView(props) {
  useEffect(() => {
    document.body.classList.add("signup-page");
    window.scrollTo(0, 0);
    document.body.scrollTop = 0;
    return function cleanup() {
      document.body.classList.remove("signup-page");
    };
  }, []);

  useEffect(() => {
    // const phone = document.getElementById('signup-phone');
    // let phoneIm = new Inputmask({
    //   regex: '+33[0-9]{1}[0-9]{2}[0-9]{2}[0-9]{2}[0-9]{2}',
    //   showMaskOnFocus: false,
    //   showMaskOnHover: false,
    // });
    // phoneIm.mask(phone);
  }, []);
  const [{ fetching, error }, signup] = useSignUp();
  const [state, setState] = useState({});
  const [logo, setLogo] = useState(null);
  const [pieceDidentite, setPieceDidentite] = useState(null);
  const [kbis, setKbis] = useState(null);
  const { isValid: passwordValid } = usePasswordCheck(state.password || "");
  const [emptyFields, setEmptyFields] = useState(false);

  const [openPassword, setOpenPassword] = useState(false);
  const [openConfirm, setOpenConfirm] = useState(false);

  const formValid =
    !emptyFields &&
    // !state.emailError &&
    // !state.phoneError &&
    passwordValid &&
    state.confirmPassword === state.password;

  const getCoordinates = async (addr) => {
    try {
      const location = await geocodeAddress(addr.value.description);
      setState({
        ...state,
        location,
      });
    } catch {
    } finally {
      setState({ ...state, address1: addr });
    }
  };

  const onSubmit = (e) => {
    e.preventDefault();
    const {
      firstname,
      lastname,
      email,
      phone,
      // city,
      // country,
      // address2,
      // address1,
      // zipcode,
      nationalite,
      password,
      confirmPassword,
    } = state;
    if (
      !firstname ||
      !lastname ||
      !email ||
      !(phone.length === 10) ||
      // !address1 ||
      !(password === confirmPassword)
    ) {
      setEmptyFields(true);
    } else {
      const payload = {
        name: { first: firstname, last: lastname },
        email,
        phone,
        // address1: address1.value.description,
        // zipcode,
        // address2,
        password,
        nationality: nationalite?.value,
      };

      let fileUploadLogo;
      if (logo) {
        fileUploadLogo = new FormData();
        fileUploadLogo.append("file", logo.file);
      }
      let fileUploadPieceDidentite;
      if (pieceDidentite) {
        fileUploadPieceDidentite = new FormData();
        fileUploadPieceDidentite.append("file", pieceDidentite.file);
      }
      let fileUploadKbis;
      if (kbis) {
        fileUploadKbis = new FormData();
        fileUploadKbis.append("file", kbis.file);
      }

      signup(payload, fileUploadLogo, fileUploadPieceDidentite, fileUploadKbis);
    }
  };

  return (
    <div className="app-container signup">
      <AuthNavbar signup />
      <div className="view-content">
        <div
          style={{ marginTop: "10vh", minHeight: "90vh" }}
          className=" bg-image login-content"
        >
          <Col sm="8">
            <h3 className="text-black text-bold auth-title">
              Créer votre compte
            </h3>
            <Form action="" onSubmit={onSubmit} className="form" method="">
              <Card className="card-login card-plain">
                <CardBody>
                  <Row>
                    <Col sm="6">
                      <AppInput
                        label="Nom"
                        value={state.lastname}
                        onChange={(t) => {
                          setState({
                            ...state,
                            lastname: t,
                          });
                        }}
                        id="signup-lastname"
                        name="signup-lastname"
                        // placeholder=""
                        type="text"
                        required
                        hasError={emptyFields && !state.lastname}
                      />
                    </Col>
                    <Col sm="6">
                      <AppInput
                        label="Prénom"
                        id="signup-firtname"
                        name="signup-firstname"
                        placeholder=""
                        type="text"
                        value={state.firstname}
                        onChange={(t) =>
                          setState({
                            ...state,
                            firstname: t,
                          })
                        }
                        required
                        hasError={emptyFields && !state.firstname}
                      />
                    </Col>
                  </Row>
                  <Row>
                    <Col sm="6">
                      <AppInput
                        label="Email"
                        value={state.email}
                        onChange={(t) => {
                          setState({
                            ...state,
                            email: t,
                          });
                        }}
                        id="signup-email"
                        name="signup-email"
                        // placeholder=""
                        type="email"
                        required
                        hasError={emptyFields && !state.email}
                      />
                    </Col>
                    <Col sm="6">
                      <AppInput
                        label="Téléphone"
                        type="number"
                        id="signup-phone"
                        name="signup-phone"
                        placeholder=""
                        type="number"
                        value={state.phone}
                        onChange={(t) => {
                          {
                            if (t.length < 11) {
                              setState({ ...state, phone: t });
                            }
                          }
                        }}
                        required
                        hasError={emptyFields && !(state.phone.length === 10)}
                      />
                    </Col>
                  </Row>

                  {/* <Row>
                    <Col sm="12">
                      <GooglePlaceInput
                        label={'Adresse'}
                        required
                        hasError={emptyFields && !state.address1}
                        // hasError
                        name="address"
                        placeholder=""
                        value={state.address1}
                        onChange={(a) => {
                          getCoordinates(a);
                        }}
                      />
                    </Col>
                  </Row> */}
                  {/* <Row>
                    <Col sm="6">
                      <AppInput
                        label="Complément d'adresse"
                        id="signup-address1"
                        name="signup-address1"
                        placeholder=""
                        type="text"
                        value={state.address2}
                        onChange={(t) => {
                          setState({
                            ...state,
                            address2: t,
                          });
                        }}
                      />
                    </Col>
                    <Col sm="6">
                      <AppInput
                        label="Code postal"
                        id="signup-zipcode"
                        name="signup-zipcode"
                        placeholder=""
                        type="number"
                        value={state.zipcode}
                        onChange={(t) => {
                          setState({
                            ...state,
                            zipcode: t,
                          });
                        }}
                        step=".01"
                      />
                    </Col>
                  </Row> */}
                  <Row>
                    <Col sm="6">
                      <AppInput
                        label="Mot de passe"
                        value={state.password}
                        onChange={(t) => {
                          setState({
                            ...state,
                            password: t,
                          });
                        }}
                        id="signup-pwd"
                        name="signup-pwd"
                        // placeholder=""
                        type={openPassword ? "text" : "password"}
                        leftaddon={
                          <div
                            onClick={() => setOpenPassword(!openPassword)}
                            className="input-addon"
                          >
                            <img
                              alt="+"
                              src={
                                openPassword
                                  ? require("assets/img/eye-b.png")
                                  : require("assets/img/eye.png")
                              }
                            />
                          </div>
                        }
                        required
                        hasError={emptyFields && !state.password}
                      />
                      {state.password && (
                        <ErrorLabel password={state.password} />
                      )}
                    </Col>
                    <Col sm="6">
                      <AppInput
                        label="Mot de passe de nouveau"
                        id="confirm-pwd"
                        name="confirm-pwd"
                        placeholder=""
                        type={openConfirm ? "text" : "password"}
                        value={state.confirmPassword}
                        onChange={(t) => {
                          setState({
                            ...state,
                            confirmPassword: t,
                          });
                        }}
                        leftaddon={
                          <div
                            onClick={() => setOpenConfirm(!openConfirm)}
                            className="input-addon"
                          >
                            <img
                              alt="+"
                              src={
                                openConfirm
                                  ? require("assets/img/eye-b.png")
                                  : require("assets/img/eye.png")
                              }
                            />
                          </div>
                        }
                        required
                        hasError={emptyFields && !state.confirmPassword}
                      />
                      {state.password &&
                        state.confirmPassword &&
                        state.confirmPassword !== state.password && (
                          <Label
                            style={{ color: "red" }}
                            className="text-black"
                          >
                            Les mots de passe ne sont pas identiques
                          </Label>
                        )}
                    </Col>
                  </Row>
                </CardBody>
                <Row>
                  <Col sm="4">
                    {error && (
                      <h6 className="auth-error">
                        {error.message || "Erreur temporaire survenue"}
                      </h6>
                    )}
                    <Button
                      block
                      className="btn-round on-secondary"
                      color="secondary"
                      size="lg"
                      type="submit"
                      // disabled={!formValid || fetching}
                    >
                      {fetching ? (
                        <Spinner size="sm" />
                      ) : (
                        <span> S'inscrire </span>
                      )}
                    </Button>
                  </Col>
                </Row>
              </Card>
            </Form>
          </Col>
        </div>
      </div>
    </div>
  );
}

export default SignupView;
