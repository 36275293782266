import React, { useState } from "react";
import PageDescription from "components/PageDescription";
import { Row, Col, Label, Button, Spinner } from "reactstrap";
import UploadButton from "components/UploadButton";
import AppInput from "components/AppInput";
import DateTimeInput from "components/DateTimeInput";
import nextId from "react-id-generator";
import CustomSimpleSelect from "components/CustomSimpleSelect";
import { useSelector } from "react-redux";
import { detailsStateSelect } from "../../../selector";
import { LIST_OPTION } from "config/app";
import { useUpdateConseillers } from "../../../hooks/useUpdateConseillers";

const Step9 = () => {
  const { info_regimes_fiscal } = useSelector(detailsStateSelect);
  const { id } = useSelector(detailsStateSelect);
  const [state, setState] = useState({
    regime_fiscal: info_regimes_fiscal?.regime_fiscal || "",
  });

  const [{ fetching, error }, updateConseillers] = useUpdateConseillers(id);
  const onUpdateData = async () => {
    const payload = {
      info_regimes_fiscal: {
        regime_fiscal: state?.regime_fiscal
      },
    };
    updateConseillers(payload);
  };

  const [emptyFields, setEmptyFields] = useState(false);

  const invalidForm =!state.regime_fiscal


  return (
    <div>
      <PageDescription title="النظام الضريبي :" />

      <Row>
        <Col xs="12" sm="4">
          <AppInput
            required
            label="ما هو النظام الضريبي الذي تخضعون له ؟ "
            type="text"
            hasError={emptyFields && !state.regime_fiscal}
            value={state.regime_fiscal}
            onChange={(t) => setState({ ...state, regime_fiscal: t })}
          />
        </Col>
      </Row>
      <Row>
        <Col xs="12" sm="8" md="8" lg="4">
          {error && <h6 className="auth-error">{error?.result?.message}</h6>}
          <div className="creation-footer">
            <Button
              className={` space-left themeColor`}
              onClick={(e) => {
                e.preventDefault();
                onUpdateData();
              }}
              size="lg"
              disabled={invalidForm}
            >
              {fetching ? (
                <Spinner size="sm" />
              ) : (
                <span className="text-bold"> تعديل الوثيقة </span>
              )}
            </Button>
          </div>
        </Col>
      </Row>
    </div>
  );
};

export default Step9;
