import React from "react";
import classNames from "classnames";
import {
  FormGroup,
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Label,
} from "reactstrap";

export default function AppInput({
  label,
  required,
  hasError,
  name,
  placeholder,
  type,
  value,
  onChange,
  pattern,
  openDropdown,
  toggleDropdown,
  valueDropdown,
  onChangeDropdown,
  listDropdown,
  ...props
}) {
  return (
    <>
      <FormGroup>
        <Label className="text-black" for={name}>
          {label}
          {required && <span>*</span>}
        </Label>
        <div
          className={classNames("input-group", {
            // matriculeLast: props.className === 'matricule-last',
            error: hasError,
          })}
        >
          <div className="input-group-append">
            <Dropdown isOpen={openDropdown} toggle={() => toggleDropdown()}>
              <DropdownToggle caret>
                {valueDropdown ? valueDropdown : ""}
              </DropdownToggle>
              <DropdownMenu>
                {listDropdown.length > 0 &&
                  listDropdown.map((item) => (
                    <>
                      <DropdownItem divider />
                      <DropdownItem
                        onClick={() => {
                          onChangeDropdown(item);
                          console.log(item);
                        }}
                      >
                        {item}
                      </DropdownItem>
                    </>
                  ))}
              </DropdownMenu>
            </Dropdown>
          </div>
        </div>
        <div>
          {props.description !== undefined && (
            <span className="app-input-desc">{props.description}</span>
          )}
        </div>
      </FormGroup>
    </>
  );
}
