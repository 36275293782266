import React, { useState, useEffect, useMemo } from "react";
import PageDescription from "components/PageDescription";
import { Row, Col, Label, Button, Spinner } from "reactstrap";
import UploadButton from "components/UploadButton";
import AppInput from "components/AppInput";
import DateTimeInput from "components/DateTimeInput";
import nextId from "react-id-generator";
import CustomSimpleSelect from "components/CustomSimpleSelect";
import { useUpdateConseillers } from "../../../hooks/useUpdateConseillers";
import { useSelector } from "react-redux";
import { detailsStateSelect } from "../../../selector";
import { LIST_OPTION } from "config/app";
import apiConfig from "config/apiConfig";
import { uploadFiles } from "actions";
const Step1 = (props) => {
  const BASE_URL = apiConfig;
  const { info_generales } = useSelector(detailsStateSelect);
  const { id } = useSelector(detailsStateSelect);

  const [state, setState] = useState({
    photo_url: info_generales?.photo_url || "",
    nom: info_generales?.nom || "",
    prenom: info_generales?.prenom || "",
    date_naissance: info_generales?.date_naissance || "",
    lieu_naissance: info_generales?.lieu_naissance || "",
    etat_civile:
      LIST_OPTION.find((item) => item.value === info_generales?.etat_civile) ||
      "",
    adresse: info_generales?.adresse || "",
    num_cine: info_generales?.num_cine || "",
    num_gsm: info_generales?.num_gsm || "",
    num_fix: info_generales?.num_fix || "",
    num_fax: info_generales?.num_fax || "",
    email: info_generales?.email || "",
  });
  const [photo, setPhoto] = useState(info_generales?.photo || "");
  const invalidForm =
    !state.nom ||
    !state.prenom ||
    !state.date_naissance ||
    !state.lieu_naissance ||
    !state.etat_civile ||
    !state.num_cine ||
    !state.num_gsm ||
    !state.num_fix ||
    !photo ||
    !state.email;

  const [emptyFields, setEmptyFields] = useState(false);
  const [{ fetching, error }, updateConseillers] = useUpdateConseillers(id);

  const onUpdateData = async () => {
    let logoData;
    let fileUpload;
    if (photo.file) {
      const fileEvent = photo?.file;
      fileUpload = new FormData();
      fileUpload.append("file", fileEvent);
      logoData = await uploadFiles(fileUpload, "file");
    }
    const payload = {
      info_generales: {
        photo: logoData ? logoData.file : photo,
        nom: state?.nom,
        prenom: state?.prenom,
        date_naissance: state?.date_naissance,
        lieu_naissance: state?.lieu_naissance,
        etat_civile: state?.etat_civile?.value,
        adresse: state?.adresse,
        num_cine: state?.num_cine,
        num_gsm: state?.num_gsm,
        num_fix: state?.num_fix,
        num_fax: state?.num_fax,
        email: state?.email,
      },
    };
    updateConseillers(payload, photo);
  };
  return (
    <div>
      <PageDescription title="معلومات عامة :" />
      <Row>
        <Col xs="12" sm="3">
          <UploadButton
            id={nextId()}
            setFileUrl={(f) => setPhoto(f)}
            rmFileUrl={() => setPhoto(null)}
            url={`https://dev.api.parlement.flexi-apps.com${state.photo_url}`}
            file={photo}
            hasError={false}
          />
        </Col>
      </Row>
      <Row>
        <Col xs="12" sm="3">
          <AppInput
            label="الإسم العائلي"
            required
            hasError={emptyFields && !state.nom}
            value={state.nom}
            onChange={(t) => setState({ ...state, nom: t })}
          />
        </Col>
        <Col xs="12" sm="3">
          <AppInput
            label="الإسم الشخصي"
            required
            hasError={emptyFields && !state.prenom}
            value={state.prenom}
            onChange={(t) => setState({ ...state, prenom: t })}
          />
        </Col>
      </Row>

      <Row>
        <Col xs="12" sm="3">
          <DateTimeInput
            label="تاريخ الازدياد"
            hasError={emptyFields && !state.date_naissance}
            required
            minDate={true}
            value={state.date_naissance}
            onChange={(e) => {
              setState({
                ...state,
                date_naissance: e,
              });
            }}
          />
        </Col>
        <Col xs="12" sm="3">
          <AppInput
            label=" مكان الازدياد"
            required
            hasError={emptyFields && !state.lieu_naissance}
            value={state.lieu_naissance}
            onChange={(t) => setState({ ...state, lieu_naissance: t })}
          />
        </Col>
        <Col xs="12" sm="3">
          <CustomSimpleSelect
            label={"الحالة العائلية"}
            required
            options={[
              { label: "أعزب", value: "CELIBATAIRE" },
              { label: "متزوج", value: "MARIE" },
              { label: "مطلق(ة)", value: "DIVORCE" },
              { label: "أرمل (ة)", value: "VEUF" },
            ]}
            onSelectValue={(v) => {
              setState({ ...state, etat_civile: v === null ? "" : v });
            }}
            value={state.etat_civile}
          />
        </Col>
      </Row>
      <Row>
        <Col xs="12" sm="3">
          <AppInput
            label=" العنوان "
            hasError={emptyFields && !state.adresse}
            value={state.adresse}
            onChange={(t) => setState({ ...state, adresse: t })}
          />
        </Col>
        <Col xs="12" sm="3">
          <AppInput
            label="رقم البطاقة الوطنية للتعريف الالكترونية"
            required
            hasError={emptyFields && !state.num_cine}
            value={state.num_cine}
            onChange={(t) => setState({ ...state, num_cine: t })}
          />
        </Col>
        <Col xs="12" sm="3">
          <AppInput
            label="البريد الإلكتروني"
            required
            hasError={emptyFields && !state.email}
            value={state.email}
            onChange={(t) => setState({ ...state, email: t })}
          />
        </Col>
      </Row>
      <Row>
        <Col xs="12" sm="3">
          <AppInput
            label=" رقم الهاتف المحمول"
            required
            type="text"
            hasError={emptyFields && !state.num_gsm}
            value={state.num_gsm}
            onChange={(t) => setState({ ...state, num_gsm: t })}
          />
        </Col>
        <Col xs="12" sm="3">
          <AppInput
            label="رقم الهاتف الثابت"
            required
            type="text"
            hasError={emptyFields && !state.num_fix}
            value={state.num_fix}
            onChange={(t) => setState({ ...state, num_fix: t })}
          />
        </Col>
        <Col xs="12" sm="3">
          <AppInput
            label="رقم الفاكس"
            type="text"
            hasError={emptyFields && !state.num_fax}
            value={state.num_fax}
            onChange={(t) => setState({ ...state, num_fax: t })}
          />
        </Col>
      </Row>
      <Row>
        <Col xs="12" sm="8" md="8" lg="4">
          {error && <h6 className="auth-error">{error?.result?.message}</h6>}
          <div className="creation-footer">
            <Button
              className={` space-left themeColor`}
              onClick={(e) => {
                e.preventDefault();
                onUpdateData();
              }}
              size="lg"
              disabled={invalidForm}
            >
              {fetching ? (
                <Spinner size="sm" />
              ) : (
                <span className="text-bold"> تعديل الوثيقة </span>
              )}
            </Button>
          </div>
        </Col>
      </Row>
    </div>
  );
};

export default Step1;
