import { combineReducers } from "redux";
import { connectRouter } from "connected-react-router";

/*
 * Import reducers
 */
import app from "./appReducer";
import auth from "modules/Auth/reducers";
import Document from "modules/Document/reducers";
import Conseillers from "modules/Conseillers/reducers";
const createReducers = (history) =>
  combineReducers({
    router: connectRouter(history),
    app,
    auth,
    Document,
    Conseillers,
  });

export default createReducers;
