import React, { useState } from "react";
import PageDescription from "components/PageDescription";
import { Row, Col, Label, Button, Spinner } from "reactstrap";
import UploadButton from "components/UploadButton";
import AppInput from "components/AppInput";
import DateTimeInput from "components/DateTimeInput";
import nextId from "react-id-generator";
import CustomSimpleSelect from "components/CustomSimpleSelect";
import { useSelector } from "react-redux";
import { detailsStateSelect } from "../../../selector";
import { LIST_OPTION } from "config/app";
import { useUpdateConseillers } from "../../../hooks/useUpdateConseillers";
const Step3 = () => {
  const { info_etudes } = useSelector(detailsStateSelect);
  const { id } = useSelector(detailsStateSelect);
  const [state, setState] = useState({
    niveau_etudes:
      LIST_OPTION.find((item) => item.value === info_etudes?.niveau_etudes) ||
      "",
    brevet_primaire:
      LIST_OPTION.find((item) => item.value === info_etudes?.brevet_primaire) ||
      "",
    brevet_college:
      LIST_OPTION.find((item) => item.value === info_etudes?.brevet_college) ||
      "",
    baccalaureat:
      LIST_OPTION.find((item) => item.value === info_etudes?.baccalaureat) ||
      "",
    diplome:
      LIST_OPTION.find((item) => item.value === info_etudes?.diplome) || "",
    autre_diplome: info_etudes?.autre_diplome || "",
    specialite: info_etudes?.specialite || "",
  });
  const [photo, setPhoto] = useState(null);

  const [emptyFields, setEmptyFields] = useState(false);

  const [{ fetching, error }, updateConseillers] = useUpdateConseillers(id);
  const onUpdateData = async () => {
    const payload = {
      info_etudes: {
        niveau_etudes: state?.niveau_etudes?.value,
        brevet_primaire: state?.brevet_primaire?.value,
        brevet_college: state?.brevet_college?.value,
        baccalaureat: state?.baccalaureat?.value,
        diplome: state?.diplome?.value,
        autre_diplome: state?.autre_diplome,
        specialite: state?.specialite,
      },
    };
    updateConseillers(payload);
  };

  const invalidForm =
    !state?.niveau_etudes ||
    !state?.brevet_primaire ||
    !state?.brevet_college ||
    !state?.baccalaureat ||
    !state?.diplome ||
    (!state?.autre_diplome && state.diplome?.value === "AUTRE") ||
    !state?.specialite;

  return (
    <div>
      <PageDescription title=" المستوى الدراسي :" />

      <Row>
        <Col xs="12" sm="4">
          <CustomSimpleSelect
            label={"المستوى الدراسي"}
            required
            options={[
              { label: "ابتدائي", value: "PRIMAIRE" },
              { label: " ثانوي إعدادي", value: "COLLEGE" },
              { label: " ثانوي تأهيلي", value: "LYCEE" },
              { label: "جامعي", value: "UNIVERSITE" },
            ]}
            value={state.niveau_etudes}
            onSelectValue={(v) => {
              setState({ ...state, niveau_etudes: v === null ? "" : v });
            }}
          />
        </Col>
        <Col xs="12" sm="4">
          <CustomSimpleSelect
            label="حاصل  على شهادة الدروس الابتدائية أو ما يعادلها   "
            required
            options={[
              { label: "نعم", value: true },
              { label: "لا", value: false },
            ]}
            value={state.brevet_primaire}
            onSelectValue={(v) => {
              setState({ ...state, brevet_primaire: v === null ? "" : v });
            }}
          />
        </Col>
      </Row>

      <Row>
        <Col xs="12" sm="4">
          <CustomSimpleSelect
            label="حاصل على شهادة الدروس الثانوية الإعدادية أو ما يعادلها "
            required
            options={[
              { label: "نعم", value: true },
              { label: "لا", value: false },
            ]}
            value={state.brevet_college}
            onSelectValue={(v) => {
              setState({ ...state, brevet_college: v === null ? "" : v });
            }}
          />
        </Col>
        <Col xs="12" sm="4">
          <CustomSimpleSelect
            label=" حاصل على شهادة البكالوريا أو ما يعادلها"
            required
            options={[
              { label: "نعم", value: true },
              { label: "لا", value: false },
            ]}
            value={state.baccalaureat}
            onSelectValue={(v) => {
              setState({ ...state, baccalaureat: v === null ? "" : v });
            }}
          />
        </Col>
      </Row>
      <Row>
        <Col xs="12" sm="8">
          <CustomSimpleSelect
            label="حاصل على إحدى الشواهد التالية"
            required
            options={[
              {
                label: "شهادة الدروس الجامعية العامة أو ما يعادلها أو باك+2",
                value: "BAC_2",
              },
              {
                label: "(الإجازة أو ما يعادلها أو باك+3 (أو باك+4",
                value: "BAC_3",
              },
              {
                label:
                  "(الماستر أو شهادة الدراسات العليا المعمقة أو دبلوم مهندس دولة أو ما يعادلها أو باك+5 (أو باك+6",
                value: "BAC_5",
              },
              {
                label: "الدكتوراه",
                value: "DOCTORAT",
              },
              { label: "أخرى", value: "AUTRE" },
            ]}
            value={state.diplome}
            onSelectValue={(v) => {
              setState({ ...state, diplome: v === null ? "" : v });
            }}
          />
        </Col>

        {state.diplome?.value === "AUTRE" && (
          <Col xs="12" sm="4">
            <AppInput
              label=" أخرى (المرجو التحديد )"
              required
              type="text"
              hasError={emptyFields && !state.autre_diplome}
              value={state.autre_diplome}
              onChange={(t) => setState({ ...state, autre_diplome: t })}
            />
          </Col>
        )}

        {(state.diplome?.value === "BAC_2" ||
          state.diplome?.value === "BAC_3" ||
          state.diplome?.value === "BAC_5" ||
          state.diplome?.value === "DOCTORAT") && (
          <Col xs="12" sm="4">
            <AppInput
              label="التخصص"
              required
              type="text"
              hasError={emptyFields && !state.specialite}
              value={state.specialite}
              onChange={(t) => setState({ ...state, specialite: t })}
            />
          </Col>
        )}
      </Row>
      <Row>
        <Col xs="12" sm="8" md="8" lg="4">
          {error && <h6 className="auth-error">{error?.result?.message}</h6>}
          <div className="creation-footer">
            <Button
              className={` space-left themeColor`}
              onClick={(e) => {
                e.preventDefault();
                onUpdateData();
              }}
              size="lg"
              disabled={invalidForm}
            >
              {fetching ? (
                <Spinner size="sm" />
              ) : (
                <span className="text-bold"> تعديل الوثيقة </span>
              )}
            </Button>
          </div>
        </Col>
      </Row>
    </div>
  );
};

export default Step3;
