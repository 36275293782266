import React, { useState, useEffect } from "react";
import "../../Conseillers.styles.scss";
import { useSelector } from "react-redux";
import { currentUserStateSelect } from "../../selector";
import { Button } from "reactstrap";
import moment from "moment";
import { Link } from "react-router-dom";
import { inviteByIdUrl } from "../../api/endpoints";
import { useDispatch } from "react-redux";
import { push } from "connected-react-router";
import { Api } from "api";
import Snackbar from "@material-ui/core/Snackbar";
import MuiAlert from "@material-ui/lab/Alert";
import Fade from "@material-ui/core/Fade";

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const SendButton = ({ userId }) => {
  const [sent, setSent] = useState({ display: false, text: "", error: false });
  const InviterUser = async () => {
    const res = await Api().post(inviteByIdUrl(userId));
    console.log(res);
    setSent({ display: true, text: res.message, error: false });
  };
  useEffect(() => {
    setTimeout(() => {
      setSent({ ...sent, display: false });
    }, 9000);
  }, [sent]);
  return (
    <>
      <Button
        style={{ width: "200px" }}
        className={`btn-round text-bold themeColor`}
        color="secondary"
        onClick={(e) => {
          // dispatch(push("/conseillers/list"));
          InviterUser(userId);
        }}
        size="md"
      >
        دعوة المستشار
      </Button>
      <Snackbar
        open={sent.display}
        onClose={() => setSent({ display: false, text: "", error: false })}
        TransitionComponent={Fade}
        key={Fade.name}
      >
        <Alert
          onClose={() => setSent({ display: false, text: "", error: false })}
          severity={sent.error ? "error" : "success"}
        >
          {sent.text}
        </Alert>
      </Snackbar>
    </>
  );
};

export const columns = [
  {
    title: "الإسم الشخصي",
    dataIndex: "nom",
    renderItem: (info, props, cb) => (
      <span style={{ cursor: "pointer" }} onClick={cb} className="text-center">
        {info.nom}
      </span>
    ),
  },
  {
    title: "الإسم العائلي",
    dataIndex: "prenom",
    renderItem: (info, props, cb) => (
      <span style={{ cursor: "pointer" }} onClick={cb} className="text-center">
        {info.prenom}
      </span>
    ),
  },
  {
    title: "البريد الإلكتروني",
    dataIndex: "email",
    renderItem: (info, props, cb) => (
      <span style={{ cursor: "pointer" }} onClick={cb} className="text-center">
        {info.email}
      </span>
    ),
  },
  {
    title: "رقم الهاتف",
    dataIndex: "phone",
    renderItem: (info, props, cb) => (
      <span style={{ cursor: "pointer" }} onClick={cb} className="text-center">
        {info.phone}
      </span>
    ),
  },
  {
    title: "",
    dataIndex: "id",
    renderItem: (info, props) => {
      return <SendButton userId={info.id} />;
    },
  },
  {
    title: "",
    dataIndex: "none",
    renderItem: (info, props, cb) => {
      return (
        <div style={{ cursor: "pointer" }} onClick={cb} className="text-left">
          <img
            style={{ transform: "rotate(180deg)" }}
            src={require("assets/img/chevron-right.png")}
            className="chevron-right"
            alt=">"
          />
        </div>
      );
    },
  },
];
