import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import classNames from "classnames";

// some actions
import { setSidebarOpen, setActiveTab } from "actions";

import Footer from "components/PageFooter";
import LogoutModal from "components/LogoutModal";

import breadcrumbsMap from "breadcrumbsMap";

import SideBar from "./SideBar";
import TopBar from "./TopBar";

import { sidebarStatusSelect, activeTabSelect } from "../selector";

const AppLayout = (props) => {
  const sidebarIsOpen = useSelector(sidebarStatusSelect);
  const activeTab = useSelector(activeTabSelect);
  const dispatch = useDispatch();
  const toggleSidebar = () => dispatch(setSidebarOpen(!sidebarIsOpen));
  const setActive = (tab) => dispatch(setActiveTab(tab));

  const [breadCrumbs, setBreadCrumbs] = useState(null);
  const [showLogout, setShowLogout] = useState(false);
  console.log("activeTab", activeTab);
  useEffect(() => {
    const brd = breadcrumbsMap.find((one) => one?.path === props.path);
    setBreadCrumbs(brd);
  }, [props.path]);

  // useEffect(() => {
  //   const pathSplit = props.path.split("/");
  //   const tabIndex = pathSplit.length >= 3 ? 2 : 1;
  //   // const tab = props.path.includes("societes")
  //   //   ? "societes"
  //   //   : pathSplit[tabIndex];
  //   // setActive(tab);
  // });

  return (
    <div className="app-layout">
      <SideBar
        toggle={toggleSidebar}
        isOpen={sidebarIsOpen}
        activeTab={activeTab}
        setTab={setActive}
        displayLogout={() => setShowLogout(true)}
      />
      <div
        className={classNames("app-view", {
          "is-sidebar-open": sidebarIsOpen,
        })}
      >
        <TopBar
          toggle={toggleSidebar}
          sidebarIsOpen={sidebarIsOpen}
          breadCrumbs={breadCrumbs}
          displayLogout={() => setShowLogout(true)}
        />
        {props.children}
        <Footer isSidebarOpen={sidebarIsOpen} />
      </div>
      {showLogout && (
        <LogoutModal
          isOpen={showLogout}
          onCloseModal={() => setShowLogout(false)}
        />
      )}
    </div>
  );
};

export default AppLayout;
