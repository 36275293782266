import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { bindActionCreators } from "redux";

import * as actions from "../../actions";
import { authStateSelect } from "../../selector";

import InfoView from "./component";

const InfoViewContainer = (props) => {
  const auth = useSelector(authStateSelect);
  const dispatch = useDispatch();
  const actionsDispatch = bindActionCreators({ ...actions }, dispatch);
  return <InfoView auth={auth} actions={actionsDispatch} {...props} />;
};

export default InfoViewContainer;
