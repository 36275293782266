import { useState } from "react";
import { useDispatch } from "react-redux";
import { push } from "connected-react-router";

// helpers
import reportError from "lib/errorHandler";

// api
import { Api } from "api";

// actions
import { forgotPasswordSuccess } from "../actions";

// endpoints
import { forgotUrl } from "../api/endpoints";

export function useForgotPass() {
  const [fetching, setFetching] = useState(false);
  const [error, setError] = useState(null);
  const dispatch = useDispatch();

  async function forgotPassword(payload) {
    try {
      setFetching(true);
      await Api().post(forgotUrl(), payload);
      dispatch(forgotPasswordSuccess(payload.username));
      dispatch(push("/auth/forgotSuccess"));
    } catch (error) {
      reportError("forgotPassword error", error);
      setError(error);
    } finally {
      setFetching(false);
    }
  }

  return [{ fetching, error }, forgotPassword];
}
