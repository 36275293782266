import React, { useContext } from "react";
import { NavItem, NavLink, Nav } from "reactstrap";
import classNames from "classnames";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";

import apiConfig from "config/apiConfig";
import { ThemeContext } from "contextProviders/ThemeProvider";

import SubMenu from "./SubMenu";

import { currentUserSelect } from "../selector";

import "assets/css/sidebar.css";
import { useDispatch } from "react-redux";
import { bindActionCreators } from "redux";

import * as actions from "../../Document/actions";
const { BASE_URL } = apiConfig;

const SideBar = ({ isOpen, toggle, activeTab, setTab, displayLogout }) => {
  const theme = useContext(ThemeContext);
  const dispatch = useDispatch();
  const actionsDispatch = bindActionCreators({ ...actions }, dispatch);
  return (
    <div className={classNames("sidebar", { "is-open": isOpen })}>
      <div className="sidebar-header">
        <span color="info" onClick={() => toggle()} style={{ color: "#000" }}>
          &times;
        </span>
        <div className="text-center">
          <img
            style={{
              display: "flex",
              margin: "0 auto",
              width: "180px",
              padding: "10px",
              height: "180px",
            }}
            className="imgFood"
            alt=".."
            src={require("assets/img/logo1.png")}
          />
        </div>
      </div>
      <div className="side-menu">
        <Nav vertical className="list-unstyled pb-3">
          <hr />
          <NavItem
            onClick={() => {
              if (window.innerWidth <= 800) {
                toggle();
              }
              setTab("Document");
            }}
            className={classNames({
              [`app-text-${theme}`]: activeTab === "Document",
            })}
          >
            <NavLink
              className={classNames({
                [`app-text-${theme}`]: activeTab === "Document",
              })}
              tag={Link}
              to={"/document/list"}
            >
              <span className="side-item-title text-bold">
                {" "}
                + قائمة الوثائق
              </span>
            </NavLink>
          </NavItem>
          <hr />
          <NavItem
            onClick={() => {
              // actionsDispatch.setCurrentConseillers([]);
              if (window.innerWidth <= 800) {
                toggle();
              }
              setTab("ConseillersCreat");
            }}
            className={classNames({
              [`app-text-${theme}`]: activeTab === "ConseillersCreat",
            })}
          >
            <NavLink
              className={classNames({
                [`app-text-${theme}`]: activeTab === "ConseillersCreat",
              })}
              tag={Link}
              to={"/conseillers/list"}
            >
              <span className="side-item-title text-bold">
                {" "}
                + قائمة المستشارين
              </span>
            </NavLink>
          </NavItem>
          <hr />
          {/* <NavItem
            onClick={() => {
              if (window.innerWidth <= 800) {
                toggle();
              }
              setTab("ConseillersCreatPlus");
            }}
            className={classNames({
              [`app-text-${theme}`]: activeTab === "DocumentCreatPlus",
            })}
          >
            <NavLink
              className={classNames({
                [`app-text-${theme}`]: activeTab === "DocumentCreatPlus",
              })}
              tag={Link}
              to={"/Document/createplusieur"}
            >
              <span className="side-item-title">
                + Créer plusieurs Expéditions
              </span>
            </NavLink>
          </NavItem>
          <hr /> */}

          {/* <hr />
          <>
            <SubMenu
              theme={theme}
              icon={
                <img
                  className="navitem-logo"
                  alt="..."
                  src={require("assets/img/iconRoom.png")}
                />
              }
              title="Utilisateurs"
              menuTab="utilisateurs"
              activeTab={activeTab}
              items={
                role === "sysadmin"
                  ? [
                      {
                        target: "/utilisateurs/admins/list",
                        title: "+ Admins",
                        tab: "admins",
                      },
                      {
                        target: "/utilisateurs/managers/list",
                        title: "+ Managers",
                        tab: "managers",
                      },
                    ]
                  : [
                      {
                        target: "/utilisateurs/managers/list",
                        title: "+ Managers",
                        tab: "managers",
                      },
                    ]
              }
              onClickTab={(t) => {
                if (window.innerWidth <= 800) {
                  toggle();
                }
                setTab(t);
              }}
            />
            <hr />
          </> */}
          <div
            tag="button"
            onClick={(e) => {
              displayLogout();
            }}
            className="sidebar-footer"
          >
            <div className="text-disconnect text-bold"> + تسجيل الخروج</div>
          </div>
        </Nav>
      </div>
    </div>
  );
};

export default SideBar;
