import React from "react";
import { Route, Redirect, Switch } from "react-router";

import routes from "./routes";

function Auth({ match, location }) {
  return (
    <div>
      <Switch>
        {routes.map((route, i) => {
          if (location.pathname === "/auth") {
            return <Redirect key={route.path} to="/auth/signin" />;
          }
          return (
            <Route
              key={i}
              path={`${match.path}${route.path}`}
              render={(props) => <route.component {...props} />}
            />
          );
        })}
      </Switch>
    </div>
  );
}

export default Auth;
